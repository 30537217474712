import { ClientError } from "graphql-request";
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AlbumImageCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId: Scalars['Int'];
  status?: InputMaybe<AlbumImageStatusEnum>;
  type: AlbumImageTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AlbumImageCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<AlbumImageCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AlbumImageCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AlbumImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AlbumImageCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AlbumImageCreateWithoutUserInput>>;
  createMany?: InputMaybe<AlbumImageCreateManyUserInputEnvelope>;
};

export type AlbumImageCreateNestedOneWithoutImageFileInput = {
  connect?: InputMaybe<AlbumImageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlbumImageCreateOrConnectWithoutImageFileInput>;
  create?: InputMaybe<AlbumImageCreateWithoutImageFileInput>;
};

export type AlbumImageCreateOrConnectWithoutImageFileInput = {
  create: AlbumImageCreateWithoutImageFileInput;
  where: AlbumImageWhereUniqueInput;
};

export type AlbumImageCreateOrConnectWithoutUserInput = {
  create: AlbumImageCreateWithoutUserInput;
  where: AlbumImageWhereUniqueInput;
};

export type AlbumImageCreateWithoutImageFileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AlbumImageStatusEnum>;
  type: AlbumImageTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutAlbumImagesInput;
};

export type AlbumImageCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  imageFile: FileUploadCreateNestedOneWithoutAlbumImageInput;
  status?: InputMaybe<AlbumImageStatusEnum>;
  type: AlbumImageTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AlbumImageScalarWhereInput = {
  AND?: InputMaybe<Array<AlbumImageScalarWhereInput>>;
  NOT?: InputMaybe<Array<AlbumImageScalarWhereInput>>;
  OR?: InputMaybe<Array<AlbumImageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  imageId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumAlbumImageStatusEnumFilter>;
  type?: InputMaybe<EnumAlbumImageTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export enum AlbumImageStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export enum AlbumImageTypeEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type AlbumImageUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AlbumImageStatusEnum>;
  type?: InputMaybe<AlbumImageTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AlbumImageUpdateManyWithWhereWithoutUserInput = {
  data: AlbumImageUpdateManyMutationInput;
  where: AlbumImageScalarWhereInput;
};

export type AlbumImageUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<AlbumImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AlbumImageCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AlbumImageCreateWithoutUserInput>>;
  createMany?: InputMaybe<AlbumImageCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AlbumImageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AlbumImageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AlbumImageWhereUniqueInput>>;
  set?: InputMaybe<Array<AlbumImageWhereUniqueInput>>;
  update?: InputMaybe<Array<AlbumImageUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AlbumImageUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AlbumImageUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AlbumImageUpdateOneWithoutImageFileInput = {
  connect?: InputMaybe<AlbumImageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlbumImageCreateOrConnectWithoutImageFileInput>;
  create?: InputMaybe<AlbumImageCreateWithoutImageFileInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AlbumImageUpdateWithoutImageFileInput>;
  upsert?: InputMaybe<AlbumImageUpsertWithoutImageFileInput>;
};

export type AlbumImageUpdateWithWhereUniqueWithoutUserInput = {
  data: AlbumImageUpdateWithoutUserInput;
  where: AlbumImageWhereUniqueInput;
};

export type AlbumImageUpdateWithoutImageFileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AlbumImageStatusEnum>;
  type?: InputMaybe<AlbumImageTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAlbumImagesInput>;
};

export type AlbumImageUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  imageFile?: InputMaybe<FileUploadUpdateOneRequiredWithoutAlbumImageInput>;
  status?: InputMaybe<AlbumImageStatusEnum>;
  type?: InputMaybe<AlbumImageTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AlbumImageUpsertWithWhereUniqueWithoutUserInput = {
  create: AlbumImageCreateWithoutUserInput;
  update: AlbumImageUpdateWithoutUserInput;
  where: AlbumImageWhereUniqueInput;
};

export type AlbumImageUpsertWithoutImageFileInput = {
  create: AlbumImageCreateWithoutImageFileInput;
  update: AlbumImageUpdateWithoutImageFileInput;
};

export type AlbumImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['Int']>;
};

export enum AppNameEnum {
  Millionbb = 'MILLIONBB',
  PricessDating = 'PRICESS_DATING'
}

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type CmsCloneUserCountWhereInput = {
  userId: Scalars['Int'];
};

export type CmsMessagesPayload = {
  __typename?: 'CmsMessagesPayload';
  count: Scalars['Int'];
  messages: Array<Message>;
};

export type CmsMessagesWhereInput = {
  message?: InputMaybe<SimpleStringFilter>;
  recipant?: InputMaybe<SimpleIntFilter>;
  recipantUser?: InputMaybe<MessageUserWhereInput>;
  sender?: InputMaybe<SimpleIntFilter>;
  senderUser?: InputMaybe<MessageUserWhereInput>;
};

export type CmsPaymentLogsPayload = {
  __typename?: 'CmsPaymentLogsPayload';
  count: Scalars['Int'];
  paymentLogs: Array<PaymentLog>;
};

export type CmsPaymentLogsWhereInput = {
  creditCardLastFour?: InputMaybe<SimpleStringFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  paymentMethod?: InputMaybe<SimplePaymentMethodEnumFilter>;
  userId?: InputMaybe<SimpleIntFilter>;
};

export type CmsReportWhereInput = {
  OR?: InputMaybe<Array<InputMaybe<CmsReportWhereInput>>>;
  createdAt?: InputMaybe<SimpleDateFilter>;
  description?: InputMaybe<SimpleStringFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  reportCategory?: InputMaybe<SimpleReportCategoryFilter>;
  reporterId?: InputMaybe<SimpleIntFilter>;
  status?: InputMaybe<SimpleReportStatusEnumFilter>;
  userId?: InputMaybe<SimpleIntFilter>;
};

export type CmsReportWhereUniqueInput = {
  id: Scalars['Int'];
};

export type CmsReportsPayload = {
  __typename?: 'CmsReportsPayload';
  count: Scalars['Int'];
  reports: Array<Report>;
};

export type CmsRevenueSummaryPayload = {
  __typename?: 'CmsRevenueSummaryPayload';
  creditCardRevenue: Scalars['Int'];
  offlineRevenue: Scalars['Int'];
};

export type CmsSubscriptionPaymentLogsSubscriptionWhereInput = {
  plan?: InputMaybe<SimpleSubscriptionPlanEnumFilter>;
  status?: InputMaybe<SimpleIntFilter>;
};

export type CmsSubscriptionPaymentLogsWhereInput = {
  OR?: InputMaybe<Array<InputMaybe<CmsSubscriptionPaymentLogsWhereInput>>>;
  creditCardLastFour?: InputMaybe<SimpleStringFilter>;
  isActiveSubscription?: InputMaybe<SimpleBooleanFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  paymentLogId?: InputMaybe<SimpleIntFilter>;
  subscription?: InputMaybe<CmsSubscriptionPaymentLogsSubscriptionWhereInput>;
  userId?: InputMaybe<SimpleIntFilter>;
};

export type CmsSubscriptionPlansWhereInput = {
  isActive?: InputMaybe<SimpleBooleanFilter>;
  isPrincess?: InputMaybe<SimpleBooleanFilter>;
};

export type CmsSubscriptionsPayload = {
  __typename?: 'CmsSubscriptionsPayload';
  count: Scalars['Int'];
  subscriptionPaymentLogs: Array<SubscriptionPaymentLog>;
};

export type CmsUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type CmsUserWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CmsUserWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CmsUserWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CmsUserWhereInput>>>;
  aboutMe?: InputMaybe<SimpleStringFilter>;
  adminRemark?: InputMaybe<SimpleStringFilter>;
  ageVerify?: InputMaybe<SimpleUserVerifyEnumFilter>;
  contactMethod?: InputMaybe<SimpleStringFilter>;
  dob?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  gender?: InputMaybe<SimpleGenderEnumFilter>;
  id?: InputMaybe<SimpleIntFilter>;
  lastIpv4?: InputMaybe<SimpleStringFilter>;
  nickname?: InputMaybe<StringFilter>;
  nonFriendMsgQuota?: InputMaybe<IntFilter>;
  phone?: InputMaybe<StringFilter>;
  rateLevel?: InputMaybe<SimpleUserRateLevelEnumFilter>;
  role?: InputMaybe<SimpleUserRoleEnumFilter>;
  simplifiedUserStatus?: InputMaybe<SimplifiedUserStatusEnum>;
  status?: InputMaybe<SimpleUserStatusEnumFilter>;
  voice?: InputMaybe<SimpleUserVerifyEnumFilter>;
};

export type CmsUsersPayload = {
  __typename?: 'CmsUsersPayload';
  count: Scalars['Int'];
  users: Array<User>;
};

export type CommentsWhereInput = {
  targetUserId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type CreateInternalFaqInput = {
  answer: Scalars['String'];
  question: Scalars['String'];
};

export enum CreditActionEnum {
  AdminEdit = 'ADMIN_EDIT',
  AdminEditNewCms = 'ADMIN_EDIT_NEW_CMS',
  AdminUpgrade = 'ADMIN_UPGRADE',
  AdminUpgradeRetool = 'ADMIN_UPGRADE_RETOOL',
  AnonymousRate = 'ANONYMOUS_RATE',
  BonusGrasRate = 'BONUS_GRAS_RATE',
  BonusGrasVerify = 'BONUS_GRAS_VERIFY',
  BonusNfmqRate = 'BONUS_NFMQ_RATE',
  FriendRequestAccept = 'FRIEND_REQUEST_ACCEPT',
  FriendRequestCancel = 'FRIEND_REQUEST_CANCEL',
  FriendRequestReject = 'FRIEND_REQUEST_REJECT',
  FriendRequestSent = 'FRIEND_REQUEST_SENT',
  MakeFriendDirectly = 'MAKE_FRIEND_DIRECTLY',
  PrivateMsg = 'PRIVATE_MSG',
  PurchaseCredit = 'PURCHASE_CREDIT',
  PurchaseFrdQuota = 'PURCHASE_FRD_QUOTA',
  PurchaseNfmq = 'PURCHASE_NFMQ',
  PurchasePremium = 'PURCHASE_PREMIUM',
  PurchaseWine = 'PURCHASE_WINE',
  RefundCredit = 'REFUND_CREDIT',
  RefundPremium = 'REFUND_PREMIUM',
  ReturnWine = 'RETURN_WINE',
  ReturnWineDeprecated = 'RETURN_WINE_DEPRECATED',
  SystemDistribute = 'SYSTEM_DISTRIBUTE',
  WineToCredit = 'WINE_TO_CREDIT'
}

export type CreditLog = {
  __typename?: 'CreditLog';
  action: Scalars['Int'];
  amount: Scalars['Int'];
  createDt: Scalars['DateTime'];
  currency: Scalars['String'];
  data?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastModifyDt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export type CreditLogCreateInput = {
  action: Scalars['Int'];
  amount: Scalars['Int'];
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  data?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutCreditLogsInput>;
};

export type CreditLogCreateManyUserInput = {
  action: Scalars['Int'];
  amount: Scalars['Int'];
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
};

export type CreditLogCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<CreditLogCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CreditLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CreditLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CreditLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<CreditLogCreateManyUserInputEnvelope>;
};

export type CreditLogCreateOrConnectWithoutUserInput = {
  create: CreditLogCreateWithoutUserInput;
  where: CreditLogWhereUniqueInput;
};

export type CreditLogCreateWithoutUserInput = {
  action: Scalars['Int'];
  amount: Scalars['Int'];
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  data?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
};

export type CreditLogOrderByWithRelationInput = {
  action?: InputMaybe<SortOrder>;
  amount?: InputMaybe<SortOrder>;
  createDt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastModifyDt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CreditLogScalarWhereInput = {
  AND?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  OR?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  action?: InputMaybe<IntFilter>;
  amount?: InputMaybe<IntFilter>;
  createDt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  data?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastModifyDt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntNullableFilter>;
};

export type CreditLogUpdateInput = {
  action?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneWithoutCreditLogsInput>;
};

export type CreditLogUpdateManyMutationInput = {
  action?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
};

export type CreditLogUpdateManyWithWhereWithoutUserInput = {
  data: CreditLogUpdateManyMutationInput;
  where: CreditLogScalarWhereInput;
};

export type CreditLogUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CreditLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CreditLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<CreditLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  set?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  update?: InputMaybe<Array<CreditLogUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CreditLogUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CreditLogUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CreditLogUpdateWithWhereUniqueWithoutUserInput = {
  data: CreditLogUpdateWithoutUserInput;
  where: CreditLogWhereUniqueInput;
};

export type CreditLogUpdateWithoutUserInput = {
  action?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
};

export type CreditLogUpsertWithWhereUniqueWithoutUserInput = {
  create: CreditLogCreateWithoutUserInput;
  update: CreditLogUpdateWithoutUserInput;
  where: CreditLogWhereUniqueInput;
};

export type CreditLogWhereInput = {
  AND?: InputMaybe<Array<CreditLogWhereInput>>;
  NOT?: InputMaybe<Array<CreditLogWhereInput>>;
  OR?: InputMaybe<Array<CreditLogWhereInput>>;
  action?: InputMaybe<IntFilter>;
  amount?: InputMaybe<IntFilter>;
  createDt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  data?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastModifyDt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<IntNullableFilter>;
};

export type CreditLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum DatatypeEnum {
  Base64 = 'BASE64',
  Text = 'TEXT'
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum EducationLevelEnum {
  AtUniversity = 'AT_UNIVERSITY',
  BachelorDegree = 'BACHELOR_DEGREE',
  HighSchool = 'HIGH_SCHOOL',
  MasterDegree = 'MASTER_DEGREE',
  OnGraduateProgram = 'ON_GRADUATE_PROGRAM',
  Phd = 'PHD',
  TradeSchool = 'TRADE_SCHOOL'
}

export type EnumAlbumImageStatusEnumFilter = {
  equals?: InputMaybe<AlbumImageStatusEnum>;
  in?: InputMaybe<Array<AlbumImageStatusEnum>>;
  not?: InputMaybe<NestedEnumAlbumImageStatusEnumFilter>;
  notIn?: InputMaybe<Array<AlbumImageStatusEnum>>;
};

export type EnumAlbumImageTypeEnumFilter = {
  equals?: InputMaybe<AlbumImageTypeEnum>;
  in?: InputMaybe<Array<AlbumImageTypeEnum>>;
  not?: InputMaybe<NestedEnumAlbumImageTypeEnumFilter>;
  notIn?: InputMaybe<Array<AlbumImageTypeEnum>>;
};

export type EnumPaymentCreditCardStatusFilter = {
  equals?: InputMaybe<PaymentCreditCardStatus>;
  in?: InputMaybe<Array<PaymentCreditCardStatus>>;
  not?: InputMaybe<NestedEnumPaymentCreditCardStatusFilter>;
  notIn?: InputMaybe<Array<PaymentCreditCardStatus>>;
};

export type EnumPremiumQuotaLogStatusFilter = {
  equals?: InputMaybe<PremiumQuotaLogStatus>;
  in?: InputMaybe<Array<PremiumQuotaLogStatus>>;
  not?: InputMaybe<NestedEnumPremiumQuotaLogStatusFilter>;
  notIn?: InputMaybe<Array<PremiumQuotaLogStatus>>;
};

export type EnumReportCategoryEnumNullableFilter = {
  equals?: InputMaybe<ReportCategoryEnum>;
  in?: InputMaybe<Array<ReportCategoryEnum>>;
  not?: InputMaybe<NestedEnumReportCategoryEnumNullableFilter>;
  notIn?: InputMaybe<Array<ReportCategoryEnum>>;
};

export type EnumReportItemEnumNullableFilter = {
  equals?: InputMaybe<ReportItemEnum>;
  in?: InputMaybe<Array<ReportItemEnum>>;
  not?: InputMaybe<NestedEnumReportItemEnumNullableFilter>;
  notIn?: InputMaybe<Array<ReportItemEnum>>;
};

export enum FileStatusEnum {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deleted = 'DELETED'
}

export type FileUpload = {
  __typename?: 'FileUpload';
  createdAt: Scalars['DateTime'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type FileUploadCreateNestedOneWithoutAlbumImageInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutAlbumImageInput>;
  create?: InputMaybe<FileUploadCreateWithoutAlbumImageInput>;
};

export type FileUploadCreateNestedOneWithoutReportImageInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutReportImageInput>;
  create?: InputMaybe<FileUploadCreateWithoutReportImageInput>;
};

export type FileUploadCreateOrConnectWithoutAlbumImageInput = {
  create: FileUploadCreateWithoutAlbumImageInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadCreateOrConnectWithoutReportImageInput = {
  create: FileUploadCreateWithoutReportImageInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadCreateWithoutAlbumImageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  height?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
  reportImage?: InputMaybe<ReportImageCreateNestedOneWithoutImageInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadCreateWithoutReportImageInput = {
  albumImage?: InputMaybe<AlbumImageCreateNestedOneWithoutImageFileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  height?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadUpdateOneRequiredWithoutAlbumImageInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutAlbumImageInput>;
  create?: InputMaybe<FileUploadCreateWithoutAlbumImageInput>;
  update?: InputMaybe<FileUploadUpdateWithoutAlbumImageInput>;
  upsert?: InputMaybe<FileUploadUpsertWithoutAlbumImageInput>;
};

export type FileUploadUpdateOneRequiredWithoutReportImageInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutReportImageInput>;
  create?: InputMaybe<FileUploadCreateWithoutReportImageInput>;
  update?: InputMaybe<FileUploadUpdateWithoutReportImageInput>;
  upsert?: InputMaybe<FileUploadUpsertWithoutReportImageInput>;
};

export type FileUploadUpdateWithoutAlbumImageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  height?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
  reportImage?: InputMaybe<ReportImageUpdateOneWithoutImageInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadUpdateWithoutReportImageInput = {
  albumImage?: InputMaybe<AlbumImageUpdateOneWithoutImageFileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  height?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadUpsertWithoutAlbumImageInput = {
  create: FileUploadCreateWithoutAlbumImageInput;
  update: FileUploadUpdateWithoutAlbumImageInput;
};

export type FileUploadUpsertWithoutReportImageInput = {
  create: FileUploadCreateWithoutReportImageInput;
  update: FileUploadUpdateWithoutReportImageInput;
};

export type FileUploadWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum GenderEnum {
  F = 'F',
  M = 'M'
}

export type GetBlacklistReocrdsPayload = {
  __typename?: 'GetBlacklistReocrdsPayload';
  blacklistedByUserIds?: Maybe<Array<Scalars['Int']>>;
  blacklistedUserIds?: Maybe<Array<Scalars['Int']>>;
};

export type GetHasIssuedNfmqThisMonthPayload = {
  __typename?: 'GetHasIssuedNfmqThisMonthPayload';
  issued: Scalars['Boolean'];
};

export type GetManyCloneUserWhereInput = {
  userId: Scalars['Int'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InternalFaq = {
  __typename?: 'InternalFaq';
  answer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  question: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum IsAdultEnum {
  Adult = 'ADULT',
  Family = 'FAMILY'
}

export enum LogTypeEnum {
  ChargeBack = 'CHARGE_BACK',
  Payment = 'PAYMENT',
  PayRequest = 'PAY_REQUEST',
  PayResponse = 'PAY_RESPONSE',
  SubscribeCancel = 'SUBSCRIBE_CANCEL',
  SubscribeExpiry = 'SUBSCRIBE_EXPIRY',
  SubscribeExtend = 'SUBSCRIBE_EXTEND',
  SubscribeInitial = 'SUBSCRIBE_INITIAL',
  SubscribeRebill = 'SUBSCRIBE_REBILL',
  SubscribeRequest = 'SUBSCRIBE_REQUEST',
  Undefined = 'UNDEFINED'
}

export type Message = {
  __typename?: 'Message';
  becomeFriendDate?: Maybe<Scalars['DateTime']>;
  createDt: Scalars['DateTime'];
  filename?: Maybe<Scalars['String']>;
  filteredMessage: Scalars['String'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  recipant: Scalars['Int'];
  recipantUser: User;
  sender: Scalars['Int'];
  senderUser: User;
  status: MessageStatusEnum;
  updateDt: Scalars['DateTime'];
};

export enum MessageStatusEnum {
  Accept = 'ACCEPT',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
  Read = 'READ',
  Reject = 'REJECT',
  Request = 'REQUEST',
  Sent = 'SENT'
}

export type MessageUserWhereInput = {
  ageVerify?: InputMaybe<SimpleUserVerifyEnumFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  rateLevel?: InputMaybe<SimpleUserRateLevelEnumFilter>;
  voice?: InputMaybe<SimpleUserVerifyEnumFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSubscriptionRebill?: Maybe<PrincessSubscription>;
  cmsCreateOneCreditLog: CreditLog;
  cmsCreateOnePaymentCreditCard: PaymentCreditCard;
  cmsCreateOneTicket: Ticket;
  cmsCreateOneUserBlacklist: UserBlacklist;
  cmsCreateOneUserFriend: UserFriend;
  cmsCreateOneUserObject: UserObject;
  cmsDeleteOneUserPhoto?: Maybe<User>;
  cmsUpdateManyCreditLog: AffectedRowsOutput;
  cmsUpdateManyPaymentCreditCard: AffectedRowsOutput;
  cmsUpdateManyTicket: AffectedRowsOutput;
  cmsUpdateManyUserBlacklist: AffectedRowsOutput;
  cmsUpdateManyUserFriend: AffectedRowsOutput;
  cmsUpdateManyUserObject: AffectedRowsOutput;
  cmsUpdateOneCreditLog?: Maybe<CreditLog>;
  cmsUpdateOnePaymentCreditCard?: Maybe<PaymentCreditCard>;
  cmsUpdateOneReport?: Maybe<Scalars['Int']>;
  cmsUpdateOneReportImage?: Maybe<Scalars['Int']>;
  cmsUpdateOneTicket?: Maybe<Ticket>;
  cmsUpdateOneUser?: Maybe<User>;
  cmsUpdateOneUserBlacklist?: Maybe<UserBlacklist>;
  cmsUpdateOneUserFriend?: Maybe<UserFriend>;
  cmsUpdateOneUserObject?: Maybe<UserObject>;
  cmsUpdateUserAdminRemark?: Maybe<Scalars['Int']>;
  cmsUpdateUserCloneGroup?: Maybe<User>;
  cmsUpdateUserCredit?: Maybe<User>;
  cmsUpgradeOneUser?: Maybe<User>;
  cmsVerifyOneUser?: Maybe<User>;
  collectMonthlyNfmq?: Maybe<Scalars['Boolean']>;
  createInternalFaq?: Maybe<InternalFaq>;
  deleteOneInternalFaq: InternalFaq;
  deleteOneUser: Scalars['Int'];
  reportUser?: Maybe<Scalars['Int']>;
  terminateSubscription?: Maybe<Subscription>;
  updateRateLevel?: Maybe<UpdateRateLevelPayload>;
  updateUserAvatar?: Maybe<User>;
  userAcceptTerms?: Maybe<User>;
  userOnboard: UserOnboardPayload;
  wineToCredit?: Maybe<WineToCreditPayload>;
};


export type MutationCmsCreateOneCreditLogArgs = {
  data: CreditLogCreateInput;
};


export type MutationCmsCreateOnePaymentCreditCardArgs = {
  data: PaymentCreditCardCreateInput;
};


export type MutationCmsCreateOneTicketArgs = {
  data: TicketCreateInput;
};


export type MutationCmsCreateOneUserBlacklistArgs = {
  data: UserBlacklistCreateInput;
};


export type MutationCmsCreateOneUserFriendArgs = {
  data: UserFriendCreateInput;
};


export type MutationCmsCreateOneUserObjectArgs = {
  data: UserObjectCreateInput;
};


export type MutationCmsDeleteOneUserPhotoArgs = {
  data: UserUpdateInput;
  reason: Scalars['String'];
  where: UserWhereUniqueInput;
};


export type MutationCmsUpdateManyCreditLogArgs = {
  data: CreditLogUpdateManyMutationInput;
  where?: InputMaybe<CreditLogWhereInput>;
};


export type MutationCmsUpdateManyPaymentCreditCardArgs = {
  data: PaymentCreditCardUpdateManyMutationInput;
  where?: InputMaybe<PaymentCreditCardWhereInput>;
};


export type MutationCmsUpdateManyTicketArgs = {
  data: TicketUpdateManyMutationInput;
  where?: InputMaybe<TicketWhereInput>;
};


export type MutationCmsUpdateManyUserBlacklistArgs = {
  data: UserBlacklistUpdateManyMutationInput;
  where?: InputMaybe<UserBlacklistWhereInput>;
};


export type MutationCmsUpdateManyUserFriendArgs = {
  data: UserFriendUpdateManyMutationInput;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type MutationCmsUpdateManyUserObjectArgs = {
  data: UserObjectUpdateManyMutationInput;
  where?: InputMaybe<UserObjectWhereInput>;
};


export type MutationCmsUpdateOneCreditLogArgs = {
  data: CreditLogUpdateInput;
  where: CreditLogWhereUniqueInput;
};


export type MutationCmsUpdateOnePaymentCreditCardArgs = {
  data: PaymentCreditCardUpdateInput;
  where: PaymentCreditCardWhereUniqueInput;
};


export type MutationCmsUpdateOneReportArgs = {
  data: ReportUpdateInput;
  where: ReportWhereUniqueInput;
};


export type MutationCmsUpdateOneReportImageArgs = {
  data: UpdateOneReportImageInput;
  id: Scalars['Int'];
};


export type MutationCmsUpdateOneTicketArgs = {
  data: TicketUpdateInput;
  where: TicketWhereUniqueInput;
};


export type MutationCmsUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationCmsUpdateOneUserBlacklistArgs = {
  data: UserBlacklistUpdateInput;
  where: UserBlacklistWhereUniqueInput;
};


export type MutationCmsUpdateOneUserFriendArgs = {
  data: UserFriendUpdateInput;
  where: UserFriendWhereUniqueInput;
};


export type MutationCmsUpdateOneUserObjectArgs = {
  data: UserObjectUpdateInput;
  where: UserObjectWhereUniqueInput;
};


export type MutationCmsUpdateUserAdminRemarkArgs = {
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  remark: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCmsUpdateUserCloneGroupArgs = {
  data: UpdateUserCloneGroupInput;
};


export type MutationCmsUpdateUserCreditArgs = {
  data: UpdateUserCreditInput;
  where: UserWhereUniqueInput;
};


export type MutationCmsUpgradeOneUserArgs = {
  data: UpgradeOneUserInput;
  where: UserWhereUniqueInput;
};


export type MutationCmsVerifyOneUserArgs = {
  data: UserUpdateInput;
  reason?: InputMaybe<Scalars['String']>;
  where: UserWhereUniqueInput;
};


export type MutationCreateInternalFaqArgs = {
  data: CreateInternalFaqInput;
};


export type MutationDeleteOneInternalFaqArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOneUserArgs = {
  userId: Scalars['Int'];
};


export type MutationReportUserArgs = {
  data: ReportUserInputArgs;
};


export type MutationTerminateSubscriptionArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateRateLevelArgs = {
  userId: Scalars['Int'];
};


export type MutationUpdateUserAvatarArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
};


export type MutationUserOnboardArgs = {
  data: UserOnboardData;
};


export type MutationWineToCreditArgs = {
  userId: Scalars['Int'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAlbumImageStatusEnumFilter = {
  equals?: InputMaybe<AlbumImageStatusEnum>;
  in?: InputMaybe<Array<AlbumImageStatusEnum>>;
  not?: InputMaybe<NestedEnumAlbumImageStatusEnumFilter>;
  notIn?: InputMaybe<Array<AlbumImageStatusEnum>>;
};

export type NestedEnumAlbumImageTypeEnumFilter = {
  equals?: InputMaybe<AlbumImageTypeEnum>;
  in?: InputMaybe<Array<AlbumImageTypeEnum>>;
  not?: InputMaybe<NestedEnumAlbumImageTypeEnumFilter>;
  notIn?: InputMaybe<Array<AlbumImageTypeEnum>>;
};

export type NestedEnumPaymentCreditCardStatusFilter = {
  equals?: InputMaybe<PaymentCreditCardStatus>;
  in?: InputMaybe<Array<PaymentCreditCardStatus>>;
  not?: InputMaybe<NestedEnumPaymentCreditCardStatusFilter>;
  notIn?: InputMaybe<Array<PaymentCreditCardStatus>>;
};

export type NestedEnumPremiumQuotaLogStatusFilter = {
  equals?: InputMaybe<PremiumQuotaLogStatus>;
  in?: InputMaybe<Array<PremiumQuotaLogStatus>>;
  not?: InputMaybe<NestedEnumPremiumQuotaLogStatusFilter>;
  notIn?: InputMaybe<Array<PremiumQuotaLogStatus>>;
};

export type NestedEnumReportCategoryEnumNullableFilter = {
  equals?: InputMaybe<ReportCategoryEnum>;
  in?: InputMaybe<Array<ReportCategoryEnum>>;
  not?: InputMaybe<NestedEnumReportCategoryEnumNullableFilter>;
  notIn?: InputMaybe<Array<ReportCategoryEnum>>;
};

export type NestedEnumReportItemEnumNullableFilter = {
  equals?: InputMaybe<ReportItemEnum>;
  in?: InputMaybe<Array<ReportItemEnum>>;
  not?: InputMaybe<NestedEnumReportItemEnumNullableFilter>;
  notIn?: InputMaybe<Array<ReportItemEnum>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type PaymentCreditCard = {
  __typename?: 'PaymentCreditCard';
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['Int'];
  last4: Scalars['String'];
  status: PaymentCreditCardStatus;
  stripeCustomerId: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

export type PaymentCreditCardCreateInput = {
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  last4: Scalars['String'];
  status?: InputMaybe<PaymentCreditCardStatus>;
  stripeCustomerId: Scalars['String'];
  user: UserCreateNestedOneWithoutPaymentMethodCreditCardsInput;
};

export type PaymentCreditCardCreateManyUserInput = {
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  last4: Scalars['String'];
  status?: InputMaybe<PaymentCreditCardStatus>;
  stripeCustomerId: Scalars['String'];
};

export type PaymentCreditCardCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<PaymentCreditCardCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentCreditCardCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PaymentCreditCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreditCardCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PaymentCreditCardCreateWithoutUserInput>>;
  createMany?: InputMaybe<PaymentCreditCardCreateManyUserInputEnvelope>;
};

export type PaymentCreditCardCreateOrConnectWithoutUserInput = {
  create: PaymentCreditCardCreateWithoutUserInput;
  where: PaymentCreditCardWhereUniqueInput;
};

export type PaymentCreditCardCreateWithoutUserInput = {
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  last4: Scalars['String'];
  status?: InputMaybe<PaymentCreditCardStatus>;
  stripeCustomerId: Scalars['String'];
};

export type PaymentCreditCardOrderByWithRelationInput = {
  brand?: InputMaybe<SortOrder>;
  expMonth?: InputMaybe<SortOrder>;
  expYear?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  last4?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type PaymentCreditCardScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentCreditCardScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentCreditCardScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentCreditCardScalarWhereInput>>;
  brand?: InputMaybe<StringFilter>;
  expMonth?: InputMaybe<IntFilter>;
  expYear?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  last4?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPaymentCreditCardStatusFilter>;
  stripeCustomerId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<IntFilter>;
};

export enum PaymentCreditCardStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type PaymentCreditCardUpdateInput = {
  brand?: InputMaybe<Scalars['String']>;
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
  last4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentCreditCardStatus>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentMethodCreditCardsInput>;
};

export type PaymentCreditCardUpdateManyMutationInput = {
  brand?: InputMaybe<Scalars['String']>;
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
  last4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentCreditCardStatus>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
};

export type PaymentCreditCardUpdateManyWithWhereWithoutUserInput = {
  data: PaymentCreditCardUpdateManyMutationInput;
  where: PaymentCreditCardScalarWhereInput;
};

export type PaymentCreditCardUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<PaymentCreditCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreditCardCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PaymentCreditCardCreateWithoutUserInput>>;
  createMany?: InputMaybe<PaymentCreditCardCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PaymentCreditCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentCreditCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentCreditCardWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentCreditCardWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentCreditCardUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PaymentCreditCardUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PaymentCreditCardUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PaymentCreditCardUpdateWithWhereUniqueWithoutUserInput = {
  data: PaymentCreditCardUpdateWithoutUserInput;
  where: PaymentCreditCardWhereUniqueInput;
};

export type PaymentCreditCardUpdateWithoutUserInput = {
  brand?: InputMaybe<Scalars['String']>;
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
  last4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentCreditCardStatus>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
};

export type PaymentCreditCardUpsertWithWhereUniqueWithoutUserInput = {
  create: PaymentCreditCardCreateWithoutUserInput;
  update: PaymentCreditCardUpdateWithoutUserInput;
  where: PaymentCreditCardWhereUniqueInput;
};

export type PaymentCreditCardWhereInput = {
  AND?: InputMaybe<Array<PaymentCreditCardWhereInput>>;
  NOT?: InputMaybe<Array<PaymentCreditCardWhereInput>>;
  OR?: InputMaybe<Array<PaymentCreditCardWhereInput>>;
  brand?: InputMaybe<StringFilter>;
  expMonth?: InputMaybe<IntFilter>;
  expYear?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  last4?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPaymentCreditCardStatusFilter>;
  stripeCustomerId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<IntFilter>;
};

export type PaymentCreditCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PaymentLog = {
  __typename?: 'PaymentLog';
  amount: Scalars['Int'];
  billingAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creditCardBrand?: Maybe<Scalars['String']>;
  creditCardLastFour?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  refTxnId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export type PaymentLogCreateManySubscriptionInput = {
  amount: Scalars['Int'];
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  data: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export type PaymentLogCreateManySubscriptionInputEnvelope = {
  data?: InputMaybe<Array<PaymentLogCreateManySubscriptionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentLogCreateManyUserInput = {
  amount: Scalars['Int'];
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  data: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<PaymentLogCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentLogCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<PaymentLogCreateManySubscriptionInputEnvelope>;
};

export type PaymentLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<PaymentLogCreateManyUserInputEnvelope>;
};

export type PaymentLogCreateNestedOneWithoutPremiumQuotaLogsInput = {
  connect?: InputMaybe<PaymentLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentLogCreateOrConnectWithoutPremiumQuotaLogsInput>;
  create?: InputMaybe<PaymentLogCreateWithoutPremiumQuotaLogsInput>;
};

export type PaymentLogCreateOrConnectWithoutPremiumQuotaLogsInput = {
  create: PaymentLogCreateWithoutPremiumQuotaLogsInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogCreateOrConnectWithoutSubscriptionInput = {
  create: PaymentLogCreateWithoutSubscriptionInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogCreateOrConnectWithoutUserInput = {
  create: PaymentLogCreateWithoutUserInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogCreateWithoutPremiumQuotaLogsInput = {
  amount: Scalars['Int'];
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  data: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductCreateNestedOneWithoutPaymentLogsInput>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<SubscriptionCreateNestedOneWithoutPaymentLogsInput>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutPaymentLogsInput;
};

export type PaymentLogCreateWithoutSubscriptionInput = {
  amount: Scalars['Int'];
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  data: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogCreateNestedManyWithoutPaymentLogInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutPaymentLogsInput>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutPaymentLogsInput;
};

export type PaymentLogCreateWithoutUserInput = {
  amount: Scalars['Int'];
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  data: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogCreateNestedManyWithoutPaymentLogInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutPaymentLogsInput>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<SubscriptionCreateNestedOneWithoutPaymentLogsInput>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  billingAddress?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creditCardBrand?: InputMaybe<StringNullableFilter>;
  creditCardLastFour?: InputMaybe<StringNullableFilter>;
  currency?: InputMaybe<StringFilter>;
  data?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringNullableFilter>;
  holderName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  logType?: InputMaybe<IntFilter>;
  paymentType?: InputMaybe<IntFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  refTxnId?: InputMaybe<StringNullableFilter>;
  subscriptionEndDate?: InputMaybe<DateTimeNullableFilter>;
  subscriptionId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type PaymentLogUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType?: InputMaybe<Scalars['Int']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogUpdateManyWithWhereWithoutSubscriptionInput = {
  data: PaymentLogUpdateManyMutationInput;
  where: PaymentLogScalarWhereInput;
};

export type PaymentLogUpdateManyWithWhereWithoutUserInput = {
  data: PaymentLogUpdateManyMutationInput;
  where: PaymentLogScalarWhereInput;
};

export type PaymentLogUpdateManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<PaymentLogCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentLogUpdateWithWhereUniqueWithoutSubscriptionInput>>;
  updateMany?: InputMaybe<Array<PaymentLogUpdateManyWithWhereWithoutSubscriptionInput>>;
  upsert?: InputMaybe<Array<PaymentLogUpsertWithWhereUniqueWithoutSubscriptionInput>>;
};

export type PaymentLogUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<PaymentLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentLogUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PaymentLogUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PaymentLogUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PaymentLogUpdateOneRequiredWithoutPremiumQuotaLogsInput = {
  connect?: InputMaybe<PaymentLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentLogCreateOrConnectWithoutPremiumQuotaLogsInput>;
  create?: InputMaybe<PaymentLogCreateWithoutPremiumQuotaLogsInput>;
  update?: InputMaybe<PaymentLogUpdateWithoutPremiumQuotaLogsInput>;
  upsert?: InputMaybe<PaymentLogUpsertWithoutPremiumQuotaLogsInput>;
};

export type PaymentLogUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: PaymentLogUpdateWithoutSubscriptionInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogUpdateWithWhereUniqueWithoutUserInput = {
  data: PaymentLogUpdateWithoutUserInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogUpdateWithoutPremiumQuotaLogsInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType?: InputMaybe<Scalars['Int']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductUpdateOneWithoutPaymentLogsInput>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<SubscriptionUpdateOneWithoutPaymentLogsInput>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentLogsInput>;
};

export type PaymentLogUpdateWithoutSubscriptionInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType?: InputMaybe<Scalars['Int']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogUpdateManyWithoutPaymentLogInput>;
  product?: InputMaybe<ProductUpdateOneWithoutPaymentLogsInput>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentLogsInput>;
};

export type PaymentLogUpdateWithoutUserInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardBrand?: InputMaybe<Scalars['String']>;
  creditCardLastFour?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  holderName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  logType?: InputMaybe<Scalars['Int']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogUpdateManyWithoutPaymentLogInput>;
  product?: InputMaybe<ProductUpdateOneWithoutPaymentLogsInput>;
  refTxnId?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<SubscriptionUpdateOneWithoutPaymentLogsInput>;
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: PaymentLogCreateWithoutSubscriptionInput;
  update: PaymentLogUpdateWithoutSubscriptionInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogUpsertWithWhereUniqueWithoutUserInput = {
  create: PaymentLogCreateWithoutUserInput;
  update: PaymentLogUpdateWithoutUserInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogUpsertWithoutPremiumQuotaLogsInput = {
  create: PaymentLogCreateWithoutPremiumQuotaLogsInput;
  update: PaymentLogUpdateWithoutPremiumQuotaLogsInput;
};

export type PaymentLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum PaymentMethodEnum {
  CreditCard = 'CREDIT_CARD',
  Offline = 'OFFLINE'
}

export enum PaymentTypeEnum {
  FxBitManual = 'FX_BIT_MANUAL',
  Offline = 'OFFLINE',
  Stripe = 'STRIPE',
  Verotel = 'VEROTEL'
}

export enum PermissionEnum {
  AllowAll = 'ALLOW_ALL',
  OnlyFriend = 'ONLY_FRIEND'
}

export type PremiumQuotaLog = {
  __typename?: 'PremiumQuotaLog';
  createdAt: Scalars['DateTime'];
  frdQuota: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  msgQuota: Scalars['Int'];
  paymentLogId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  userId: Scalars['Int'];
  willIssueAt: Scalars['DateTime'];
};

export type PremiumQuotaLogCreateManyPaymentLogInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  msgQuota: Scalars['Int'];
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  status: PremiumQuotaLogStatus;
  subscriptionId: Scalars['Int'];
  userId: Scalars['Int'];
  willIssueAt: Scalars['DateTime'];
};

export type PremiumQuotaLogCreateManyPaymentLogInputEnvelope = {
  data?: InputMaybe<Array<PremiumQuotaLogCreateManyPaymentLogInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PremiumQuotaLogCreateManySubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  msgQuota: Scalars['Int'];
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  paymentLogId: Scalars['Int'];
  status: PremiumQuotaLogStatus;
  userId: Scalars['Int'];
  willIssueAt: Scalars['DateTime'];
};

export type PremiumQuotaLogCreateManySubscriptionInputEnvelope = {
  data?: InputMaybe<Array<PremiumQuotaLogCreateManySubscriptionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PremiumQuotaLogCreateNestedManyWithoutPaymentLogInput = {
  connect?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PremiumQuotaLogCreateOrConnectWithoutPaymentLogInput>>;
  create?: InputMaybe<Array<PremiumQuotaLogCreateWithoutPaymentLogInput>>;
  createMany?: InputMaybe<PremiumQuotaLogCreateManyPaymentLogInputEnvelope>;
};

export type PremiumQuotaLogCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PremiumQuotaLogCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<PremiumQuotaLogCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<PremiumQuotaLogCreateManySubscriptionInputEnvelope>;
};

export type PremiumQuotaLogCreateOrConnectWithoutPaymentLogInput = {
  create: PremiumQuotaLogCreateWithoutPaymentLogInput;
  where: PremiumQuotaLogWhereUniqueInput;
};

export type PremiumQuotaLogCreateOrConnectWithoutSubscriptionInput = {
  create: PremiumQuotaLogCreateWithoutSubscriptionInput;
  where: PremiumQuotaLogWhereUniqueInput;
};

export type PremiumQuotaLogCreateWithoutPaymentLogInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota: Scalars['Int'];
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  msgQuota: Scalars['Int'];
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  status: PremiumQuotaLogStatus;
  subscription?: InputMaybe<SubscriptionCreateNestedOneWithoutPremiumQuotaLogsInput>;
  userId: Scalars['Int'];
  willIssueAt: Scalars['DateTime'];
};

export type PremiumQuotaLogCreateWithoutSubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota: Scalars['Int'];
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  msgQuota: Scalars['Int'];
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  paymentLog: PaymentLogCreateNestedOneWithoutPremiumQuotaLogsInput;
  status: PremiumQuotaLogStatus;
  userId: Scalars['Int'];
  willIssueAt: Scalars['DateTime'];
};

export type PremiumQuotaLogScalarWhereInput = {
  AND?: InputMaybe<Array<PremiumQuotaLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<PremiumQuotaLogScalarWhereInput>>;
  OR?: InputMaybe<Array<PremiumQuotaLogScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  frdQuota?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  issuedAt?: InputMaybe<DateTimeNullableFilter>;
  last_modify_dt?: InputMaybe<DateTimeFilter>;
  msgQuota?: InputMaybe<IntFilter>;
  nonFriendMsgQuota?: InputMaybe<IntFilter>;
  paymentLogId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumPremiumQuotaLogStatusFilter>;
  subscriptionId?: InputMaybe<IntFilter>;
  userId?: InputMaybe<IntFilter>;
  willIssueAt?: InputMaybe<DateTimeFilter>;
};

export enum PremiumQuotaLogStatus {
  Issued = 'ISSUED',
  Pending = 'PENDING'
}

export type PremiumQuotaLogUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<PremiumQuotaLogStatus>;
  userId?: InputMaybe<Scalars['Int']>;
  willIssueAt?: InputMaybe<Scalars['DateTime']>;
};

export type PremiumQuotaLogUpdateManyWithWhereWithoutPaymentLogInput = {
  data: PremiumQuotaLogUpdateManyMutationInput;
  where: PremiumQuotaLogScalarWhereInput;
};

export type PremiumQuotaLogUpdateManyWithWhereWithoutSubscriptionInput = {
  data: PremiumQuotaLogUpdateManyMutationInput;
  where: PremiumQuotaLogScalarWhereInput;
};

export type PremiumQuotaLogUpdateManyWithoutPaymentLogInput = {
  connect?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PremiumQuotaLogCreateOrConnectWithoutPaymentLogInput>>;
  create?: InputMaybe<Array<PremiumQuotaLogCreateWithoutPaymentLogInput>>;
  createMany?: InputMaybe<PremiumQuotaLogCreateManyPaymentLogInputEnvelope>;
  delete?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PremiumQuotaLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  set?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  update?: InputMaybe<Array<PremiumQuotaLogUpdateWithWhereUniqueWithoutPaymentLogInput>>;
  updateMany?: InputMaybe<Array<PremiumQuotaLogUpdateManyWithWhereWithoutPaymentLogInput>>;
  upsert?: InputMaybe<Array<PremiumQuotaLogUpsertWithWhereUniqueWithoutPaymentLogInput>>;
};

export type PremiumQuotaLogUpdateManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PremiumQuotaLogCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<PremiumQuotaLogCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<PremiumQuotaLogCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PremiumQuotaLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  set?: InputMaybe<Array<PremiumQuotaLogWhereUniqueInput>>;
  update?: InputMaybe<Array<PremiumQuotaLogUpdateWithWhereUniqueWithoutSubscriptionInput>>;
  updateMany?: InputMaybe<Array<PremiumQuotaLogUpdateManyWithWhereWithoutSubscriptionInput>>;
  upsert?: InputMaybe<Array<PremiumQuotaLogUpsertWithWhereUniqueWithoutSubscriptionInput>>;
};

export type PremiumQuotaLogUpdateWithWhereUniqueWithoutPaymentLogInput = {
  data: PremiumQuotaLogUpdateWithoutPaymentLogInput;
  where: PremiumQuotaLogWhereUniqueInput;
};

export type PremiumQuotaLogUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: PremiumQuotaLogUpdateWithoutSubscriptionInput;
  where: PremiumQuotaLogWhereUniqueInput;
};

export type PremiumQuotaLogUpdateWithoutPaymentLogInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<PremiumQuotaLogStatus>;
  subscription?: InputMaybe<SubscriptionUpdateOneWithoutPremiumQuotaLogsInput>;
  userId?: InputMaybe<Scalars['Int']>;
  willIssueAt?: InputMaybe<Scalars['DateTime']>;
};

export type PremiumQuotaLogUpdateWithoutSubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  paymentLog?: InputMaybe<PaymentLogUpdateOneRequiredWithoutPremiumQuotaLogsInput>;
  status?: InputMaybe<PremiumQuotaLogStatus>;
  userId?: InputMaybe<Scalars['Int']>;
  willIssueAt?: InputMaybe<Scalars['DateTime']>;
};

export type PremiumQuotaLogUpsertWithWhereUniqueWithoutPaymentLogInput = {
  create: PremiumQuotaLogCreateWithoutPaymentLogInput;
  update: PremiumQuotaLogUpdateWithoutPaymentLogInput;
  where: PremiumQuotaLogWhereUniqueInput;
};

export type PremiumQuotaLogUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: PremiumQuotaLogCreateWithoutSubscriptionInput;
  update: PremiumQuotaLogUpdateWithoutSubscriptionInput;
  where: PremiumQuotaLogWhereUniqueInput;
};

export type PremiumQuotaLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PrincessComment = {
  __typename?: 'PrincessComment';
  anonymousDate?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  rate?: Maybe<Scalars['Float']>;
  targetUser?: Maybe<PrincessUser>;
  targetUserId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<PrincessUser>;
  userId: Scalars['Int'];
};

export type PrincessPaymentLog = {
  __typename?: 'PrincessPaymentLog';
  amount: Scalars['Int'];
  billingAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creditCardBrand?: Maybe<Scalars['String']>;
  creditCardLastFour?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  logType: LogTypeEnum;
  paymentType: PaymentTypeEnum;
  phone?: Maybe<Scalars['String']>;
  product?: Maybe<PrincessProduct>;
  refTxnId?: Maybe<Scalars['String']>;
  subscription?: Maybe<PrincessSubscription>;
  subscriptionId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
};

export type PrincessProduct = {
  __typename?: 'PrincessProduct';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['String'];
  price: Scalars['Int'];
  rewardQuantity: Scalars['Int'];
  type: ProductType;
  updatedAt: Scalars['DateTime'];
};

export type PrincessReport = {
  __typename?: 'PrincessReport';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  images: Array<PrincessReportImage>;
  reporter: PrincessUser;
  reporterId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<PrincessUser>;
  userId?: Maybe<Scalars['Int']>;
};

export type PrincessReportImage = {
  __typename?: 'PrincessReportImage';
  id: Scalars['Int'];
  reportId: Scalars['Int'];
  url: Scalars['String'];
};

export type PrincessSubscription = {
  __typename?: 'PrincessSubscription';
  amount: Scalars['Int'];
  createDt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['Int'];
  lastModifyDt: Scalars['DateTime'];
  nextChargeDt: Scalars['DateTime'];
  plan: Scalars['String'];
  status: Scalars['Int'];
  subscriptionPlan?: Maybe<PrincessSubscriptionPlan>;
};

export type PrincessSubscriptionPlan = {
  __typename?: 'PrincessSubscriptionPlan';
  activeDays: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  frdQuota: Scalars['Int'];
  id: Scalars['String'];
  isPrincess: Scalars['Boolean'];
  nonFriendMsgQuota: Scalars['Int'];
  role: UserRoleEnum;
  updatedAt: Scalars['DateTime'];
};

export type PrincessUser = {
  __typename?: 'PrincessUser';
  ageVerify: UserVerifyEnum;
  avatar?: Maybe<Scalars['String']>;
  avatarBanned: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['Date']>;
  gender: GenderEnum;
  id: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  rateLevel: UserRateLevelEnum;
  role: UserRoleEnum;
  status: UserStatusEnum;
  voice: UserVerifyEnum;
};

export type Product = {
  __typename?: 'Product';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  price: Scalars['Int'];
  rewardQuantity: Scalars['Int'];
  type: ProductType;
  updatedAt: Scalars['DateTime'];
};

export type ProductCreateNestedOneWithoutPaymentLogsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<ProductCreateWithoutPaymentLogsInput>;
};

export type ProductCreateOrConnectWithoutPaymentLogsInput = {
  create: ProductCreateWithoutPaymentLogsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutPaymentLogsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Int'];
  rewardQuantity: Scalars['Int'];
  type: ProductType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum ProductType {
  Credit = 'CREDIT',
  FrdQuota = 'FRD_QUOTA',
  NonFrdMsgQuota = 'NON_FRD_MSG_QUOTA'
}

export type ProductUpdateOneWithoutPaymentLogsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<ProductCreateWithoutPaymentLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductUpdateWithoutPaymentLogsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutPaymentLogsInput>;
};

export type ProductUpdateWithoutPaymentLogsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Int']>;
  rewardQuantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductUpsertWithoutPaymentLogsInput = {
  create: ProductCreateWithoutPaymentLogsInput;
  update: ProductUpdateWithoutPaymentLogsInput;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  cmsCloneUserCount: Scalars['Int'];
  cmsGetManyCloneUser: Array<User>;
  cmsGetManyCreditLog: Array<CreditLog>;
  cmsGetManyPaymentCreditCard: Array<PaymentCreditCard>;
  cmsGetManyTicket: Array<Ticket>;
  cmsGetManyTicketGroup?: Maybe<Array<Maybe<Ticket>>>;
  cmsGetManyUser: Array<Maybe<User>>;
  cmsGetManyUserBlacklist: Array<UserBlacklist>;
  cmsGetManyUserFriend: Array<UserFriend>;
  cmsGetManyUserObject: Array<UserObject>;
  cmsGetOneCreditLog?: Maybe<CreditLog>;
  cmsGetOnePaymentCreditCard?: Maybe<PaymentCreditCard>;
  cmsGetOneTicket?: Maybe<Ticket>;
  cmsGetOneUser?: Maybe<User>;
  cmsGetOneUserBlacklist?: Maybe<UserBlacklist>;
  cmsGetOneUserFriend?: Maybe<UserFriend>;
  cmsGetOneUserObject?: Maybe<UserObject>;
  cmsGetUserAdminRemark: UserAdminRemark;
  cmsInternalFaqs: Array<InternalFaq>;
  cmsMessageCount: Scalars['Int'];
  cmsMessages: CmsMessagesPayload;
  cmsPaymentLogs?: Maybe<CmsPaymentLogsPayload>;
  cmsPaymentLogsCount: Scalars['Int'];
  cmsReportCount: Scalars['Int'];
  cmsReports: CmsReportsPayload;
  cmsRevenueSummary?: Maybe<CmsRevenueSummaryPayload>;
  cmsSubscriptionCount: Scalars['Int'];
  cmsSubscriptionPlans: Array<SubscriptionPlan>;
  cmsSubscriptions?: Maybe<CmsSubscriptionsPayload>;
  cmsUserCount: Scalars['Int'];
  cmsUsers?: Maybe<CmsUsersPayload>;
  commentCount?: Maybe<Scalars['Int']>;
  comments: Array<PrincessComment>;
  convertedWineCreditLog?: Maybe<CreditLog>;
  friendCount: Scalars['Int'];
  friends: Array<PrincessUser>;
  getBlacklistReocrds?: Maybe<GetBlacklistReocrdsPayload>;
  hasIssuedNfmqThisMonth?: Maybe<GetHasIssuedNfmqThisMonthPayload>;
  paymentLogCount: Scalars['Int'];
  paymentLogs: Array<PrincessPaymentLog>;
  products: Array<Product>;
  searchUsers: SearchUsersPayload;
  ticketGroupCount?: Maybe<Scalars['Int']>;
  userCount?: Maybe<Scalars['Int']>;
  viewCount: Scalars['Int'];
  views: Array<PrincessUser>;
};


export type QueryCmsCloneUserCountArgs = {
  where: CmsCloneUserCountWhereInput;
};


export type QueryCmsGetManyCloneUserArgs = {
  where: GetManyCloneUserWhereInput;
};


export type QueryCmsGetManyCreditLogArgs = {
  after?: InputMaybe<CreditLogWhereUniqueInput>;
  before?: InputMaybe<CreditLogWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CreditLogOrderByWithRelationInput>>;
  where?: InputMaybe<CreditLogWhereInput>;
};


export type QueryCmsGetManyPaymentCreditCardArgs = {
  after?: InputMaybe<PaymentCreditCardWhereUniqueInput>;
  before?: InputMaybe<PaymentCreditCardWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentCreditCardOrderByWithRelationInput>>;
  where?: InputMaybe<PaymentCreditCardWhereInput>;
};


export type QueryCmsGetManyTicketArgs = {
  after?: InputMaybe<TicketWhereUniqueInput>;
  before?: InputMaybe<TicketWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketOrderByWithRelationInput>>;
  where?: InputMaybe<TicketWhereInput>;
};


export type QueryCmsGetManyTicketGroupArgs = {
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TicketWhereInput>;
};


export type QueryCmsGetManyUserArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderByWithRelationInput>>>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryCmsGetManyUserBlacklistArgs = {
  after?: InputMaybe<UserBlacklistWhereUniqueInput>;
  before?: InputMaybe<UserBlacklistWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserBlacklistOrderByWithRelationInput>>;
  where?: InputMaybe<UserBlacklistWhereInput>;
};


export type QueryCmsGetManyUserFriendArgs = {
  after?: InputMaybe<UserFriendWhereUniqueInput>;
  before?: InputMaybe<UserFriendWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithRelationInput>>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type QueryCmsGetManyUserObjectArgs = {
  after?: InputMaybe<UserObjectWhereUniqueInput>;
  before?: InputMaybe<UserObjectWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserObjectOrderByWithRelationInput>>;
  where?: InputMaybe<UserObjectWhereInput>;
};


export type QueryCmsGetOneCreditLogArgs = {
  where: CreditLogWhereUniqueInput;
};


export type QueryCmsGetOnePaymentCreditCardArgs = {
  where: PaymentCreditCardWhereUniqueInput;
};


export type QueryCmsGetOneTicketArgs = {
  where: TicketWhereUniqueInput;
};


export type QueryCmsGetOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryCmsGetOneUserBlacklistArgs = {
  where: UserBlacklistWhereUniqueInput;
};


export type QueryCmsGetOneUserFriendArgs = {
  where: UserFriendWhereUniqueInput;
};


export type QueryCmsGetOneUserObjectArgs = {
  where: UserObjectWhereUniqueInput;
};


export type QueryCmsGetUserAdminRemarkArgs = {
  where: UserWhereUniqueInput;
};


export type QueryCmsMessagesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsMessagesWhereInput>;
};


export type QueryCmsPaymentLogsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsPaymentLogsWhereInput>;
};


export type QueryCmsPaymentLogsCountArgs = {
  where?: InputMaybe<CmsPaymentLogsWhereInput>;
};


export type QueryCmsReportCountArgs = {
  where?: InputMaybe<CmsReportWhereInput>;
};


export type QueryCmsReportsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: CmsReportWhereInput;
};


export type QueryCmsSubscriptionCountArgs = {
  where?: InputMaybe<CmsSubscriptionPaymentLogsWhereInput>;
};


export type QueryCmsSubscriptionPlansArgs = {
  where: CmsSubscriptionPlansWhereInput;
};


export type QueryCmsSubscriptionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsSubscriptionPaymentLogsWhereInput>;
};


export type QueryCmsUserCountArgs = {
  where?: InputMaybe<CmsUserWhereInput>;
};


export type QueryCmsUsersArgs = {
  orderBy?: InputMaybe<Array<CmsUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsUserWhereInput>;
};


export type QueryCommentCountArgs = {
  where: CommentsWhereInput;
};


export type QueryCommentsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: CommentsWhereInput;
};


export type QueryFriendsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentLogsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchUsersArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  where?: SearchUsersWhereInput;
};


export type QueryTicketGroupCountArgs = {
  where?: InputMaybe<TicketWhereInput>;
};


export type QueryUserCountArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type QueryViewCountArgs = {
  where: ViewsWhereInput;
};


export type QueryViewsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: ViewsWhereInput;
};

export type RateSummary = {
  __typename?: 'RateSummary';
  averageRate: Scalars['Float'];
  totalRaters: Scalars['Int'];
};

export type Report = {
  __typename?: 'Report';
  becomeFriendDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  images: Array<ReportImage>;
  remark?: Maybe<Scalars['String']>;
  reportCategory?: Maybe<ReportCategoryEnum>;
  reportItem?: Maybe<ReportItemEnum>;
  reporter: User;
  reporterId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export enum ReportCategory {
  Commercial = 'COMMERCIAL',
  Discrimination = 'DISCRIMINATION',
  Fraud = 'FRAUD',
  Sexual = 'SEXUAL',
  Violence = 'VIOLENCE'
}

export enum ReportCategoryEnum {
  Commercial = 'COMMERCIAL',
  Discrimination = 'DISCRIMINATION',
  Fraud = 'FRAUD',
  Sexual = 'SEXUAL',
  Violence = 'VIOLENCE'
}

export type ReportCreateManyReporterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type ReportCreateManyReporterInputEnvelope = {
  data?: InputMaybe<Array<ReportCreateManyReporterInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReportCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  reporterId: Scalars['Int'];
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<ReportCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReportCreateNestedManyWithoutReporterInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutReporterInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutReporterInput>>;
  createMany?: InputMaybe<ReportCreateManyReporterInputEnvelope>;
};

export type ReportCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutUserInput>>;
  createMany?: InputMaybe<ReportCreateManyUserInputEnvelope>;
};

export type ReportCreateNestedOneWithoutImagesInput = {
  connect?: InputMaybe<ReportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportCreateOrConnectWithoutImagesInput>;
  create?: InputMaybe<ReportCreateWithoutImagesInput>;
};

export type ReportCreateOrConnectWithoutImagesInput = {
  create: ReportCreateWithoutImagesInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateOrConnectWithoutReporterInput = {
  create: ReportCreateWithoutReporterInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateOrConnectWithoutUserInput = {
  create: ReportCreateWithoutUserInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateWithoutImagesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  reporter: UserCreateNestedOneWithoutReporterInput;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutReporteeInput>;
};

export type ReportCreateWithoutReporterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<ReportImageCreateNestedManyWithoutReportInput>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutReporteeInput>;
};

export type ReportCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<ReportImageCreateNestedManyWithoutReportInput>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  reporter: UserCreateNestedOneWithoutReporterInput;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportImage = {
  __typename?: 'ReportImage';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  image: FileUpload;
  reportId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ReportImageCreateManyReportInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  imageId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportImageCreateManyReportInputEnvelope = {
  data?: InputMaybe<Array<ReportImageCreateManyReportInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReportImageCreateNestedManyWithoutReportInput = {
  connect?: InputMaybe<Array<ReportImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportImageCreateOrConnectWithoutReportInput>>;
  create?: InputMaybe<Array<ReportImageCreateWithoutReportInput>>;
  createMany?: InputMaybe<ReportImageCreateManyReportInputEnvelope>;
};

export type ReportImageCreateNestedOneWithoutImageInput = {
  connect?: InputMaybe<ReportImageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportImageCreateOrConnectWithoutImageInput>;
  create?: InputMaybe<ReportImageCreateWithoutImageInput>;
};

export type ReportImageCreateOrConnectWithoutImageInput = {
  create: ReportImageCreateWithoutImageInput;
  where: ReportImageWhereUniqueInput;
};

export type ReportImageCreateOrConnectWithoutReportInput = {
  create: ReportImageCreateWithoutReportInput;
  where: ReportImageWhereUniqueInput;
};

export type ReportImageCreateWithoutImageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  report: ReportCreateNestedOneWithoutImagesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportImageCreateWithoutReportInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  image: FileUploadCreateNestedOneWithoutReportImageInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportImageScalarWhereInput = {
  AND?: InputMaybe<Array<ReportImageScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReportImageScalarWhereInput>>;
  OR?: InputMaybe<Array<ReportImageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  imageId?: InputMaybe<IntFilter>;
  reportId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportImageUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportImageUpdateManyWithWhereWithoutReportInput = {
  data: ReportImageUpdateManyMutationInput;
  where: ReportImageScalarWhereInput;
};

export type ReportImageUpdateManyWithoutReportInput = {
  connect?: InputMaybe<Array<ReportImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportImageCreateOrConnectWithoutReportInput>>;
  create?: InputMaybe<Array<ReportImageCreateWithoutReportInput>>;
  createMany?: InputMaybe<ReportImageCreateManyReportInputEnvelope>;
  delete?: InputMaybe<Array<ReportImageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportImageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportImageWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportImageWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportImageUpdateWithWhereUniqueWithoutReportInput>>;
  updateMany?: InputMaybe<Array<ReportImageUpdateManyWithWhereWithoutReportInput>>;
  upsert?: InputMaybe<Array<ReportImageUpsertWithWhereUniqueWithoutReportInput>>;
};

export type ReportImageUpdateOneWithoutImageInput = {
  connect?: InputMaybe<ReportImageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportImageCreateOrConnectWithoutImageInput>;
  create?: InputMaybe<ReportImageCreateWithoutImageInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ReportImageUpdateWithoutImageInput>;
  upsert?: InputMaybe<ReportImageUpsertWithoutImageInput>;
};

export type ReportImageUpdateWithWhereUniqueWithoutReportInput = {
  data: ReportImageUpdateWithoutReportInput;
  where: ReportImageWhereUniqueInput;
};

export type ReportImageUpdateWithoutImageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  report?: InputMaybe<ReportUpdateOneRequiredWithoutImagesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportImageUpdateWithoutReportInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  image?: InputMaybe<FileUploadUpdateOneRequiredWithoutReportImageInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportImageUpsertWithWhereUniqueWithoutReportInput = {
  create: ReportImageCreateWithoutReportInput;
  update: ReportImageUpdateWithoutReportInput;
  where: ReportImageWhereUniqueInput;
};

export type ReportImageUpsertWithoutImageInput = {
  create: ReportImageCreateWithoutImageInput;
  update: ReportImageUpdateWithoutImageInput;
};

export type ReportImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['Int']>;
};

export enum ReportItem {
  Message = 'MESSAGE',
  Others = 'OTHERS',
  Profile = 'PROFILE'
}

export enum ReportItemEnum {
  Message = 'MESSAGE',
  Others = 'OTHERS',
  Profile = 'PROFILE'
}

export type ReportScalarWhereInput = {
  AND?: InputMaybe<Array<ReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReportScalarWhereInput>>;
  OR?: InputMaybe<Array<ReportScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  filename?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  remark?: InputMaybe<StringNullableFilter>;
  reportCategory?: InputMaybe<EnumReportCategoryEnumNullableFilter>;
  reportItem?: InputMaybe<EnumReportItemEnumNullableFilter>;
  reporterId?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntNullableFilter>;
};

export enum ReportStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Invalid = 'INVALID',
  Proceeded = 'PROCEEDED'
}

/** This is a description of a Node */
export type ReportUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReportStatusEnum>;
};

export type ReportUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportUpdateManyWithWhereWithoutReporterInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithWhereWithoutUserInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithoutReporterInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutReporterInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutReporterInput>>;
  createMany?: InputMaybe<ReportCreateManyReporterInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutReporterInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutReporterInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutReporterInput>>;
};

export type ReportUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutUserInput>>;
  createMany?: InputMaybe<ReportCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ReportUpdateOneRequiredWithoutImagesInput = {
  connect?: InputMaybe<ReportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportCreateOrConnectWithoutImagesInput>;
  create?: InputMaybe<ReportCreateWithoutImagesInput>;
  update?: InputMaybe<ReportUpdateWithoutImagesInput>;
  upsert?: InputMaybe<ReportUpsertWithoutImagesInput>;
};

export type ReportUpdateWithWhereUniqueWithoutReporterInput = {
  data: ReportUpdateWithoutReporterInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithWhereUniqueWithoutUserInput = {
  data: ReportUpdateWithoutUserInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithoutImagesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  reporter?: InputMaybe<UserUpdateOneRequiredWithoutReporterInput>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneWithoutReporteeInput>;
};

export type ReportUpdateWithoutReporterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<ReportImageUpdateManyWithoutReportInput>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneWithoutReporteeInput>;
};

export type ReportUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<ReportImageUpdateManyWithoutReportInput>;
  remark?: InputMaybe<Scalars['String']>;
  reportCategory?: InputMaybe<ReportCategoryEnum>;
  reportItem?: InputMaybe<ReportItemEnum>;
  reporter?: InputMaybe<UserUpdateOneRequiredWithoutReporterInput>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportUpsertWithWhereUniqueWithoutReporterInput = {
  create: ReportCreateWithoutReporterInput;
  update: ReportUpdateWithoutReporterInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpsertWithWhereUniqueWithoutUserInput = {
  create: ReportCreateWithoutUserInput;
  update: ReportUpdateWithoutUserInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpsertWithoutImagesInput = {
  create: ReportCreateWithoutImagesInput;
  update: ReportUpdateWithoutImagesInput;
};

export type ReportUserInputArgs = {
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  reason: Scalars['String'];
  reportCategory: ReportCategory;
  reportItem: ReportItem;
  userId: Scalars['Int'];
};

export type ReportWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum RestrictedLevelEnum {
  Banned = 'BANNED',
  BannedPending = 'BANNED_PENDING',
  MillionbbOnly = 'MILLIONBB_ONLY',
  MillionbbOnlyPending = 'MILLIONBB_ONLY_PENDING',
  Normal = 'NORMAL'
}

export enum SearchTargetEnum {
  Considering = 'CONSIDERING',
  LongOrShortTerm = 'LONG_OR_SHORT_TERM',
  LongTerm = 'LONG_TERM',
  NewFriend = 'NEW_FRIEND',
  ShortOrLongTerm = 'SHORT_OR_LONG_TERM',
  ShortTerm = 'SHORT_TERM'
}

export type SearchUser = {
  __typename?: 'SearchUser';
  ageVerify: UserVerifyEnum;
  avatar?: Maybe<Scalars['String']>;
  avatarBanned: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  contactPrivacy?: Maybe<UserContactPrivacyEnum>;
  createdAt: Scalars['DateTime'];
  dob?: Maybe<Scalars['DateTime']>;
  educationLevel?: Maybe<EducationLevelEnum>;
  gender: GenderEnum;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastSeen: Scalars['DateTime'];
  logo?: Maybe<Scalars['String']>;
  logoFull?: Maybe<Scalars['String']>;
  logoHalf?: Maybe<Scalars['String']>;
  minFrdWine?: Maybe<Scalars['Int']>;
  nickname?: Maybe<Scalars['String']>;
  rateLevel: UserRateLevelEnum;
  role: UserRoleEnum;
  searchTarget: SearchTargetEnum;
  status: UserStatusEnum;
  updatedAt: Scalars['DateTime'];
  voice: UserVerifyEnum;
  weight?: Maybe<Scalars['Int']>;
};

export type SearchUsersPayload = {
  __typename?: 'SearchUsersPayload';
  count: Scalars['Int'];
  users: Array<SearchUser>;
};

export type SearchUsersWhereInput = {
  aboutBody?: InputMaybe<SimpleUserAboutBodyEnumFilter>;
  aboutDrink?: InputMaybe<SimpleUserAboutDrinkEnumFilter>;
  aboutEconomy?: InputMaybe<SimpleUserAboutEconomyEnumFilter>;
  aboutHeight?: InputMaybe<SimpleUserAboutHeightEnumFilter>;
  aboutSmoke?: InputMaybe<SimpleUserAboutSmokeEnumFilter>;
  aboutWaist?: InputMaybe<SimpleUserAboutWaistEnumFilter>;
  ageVerify?: InputMaybe<SimpleUserVerifyEnumFilter>;
  city?: InputMaybe<SimpleStringFilter>;
  contactPrivacy?: InputMaybe<SimpleUserContactPrivacyEnumFilter>;
  dob?: InputMaybe<SimpleDateFilter>;
  educationLevel?: InputMaybe<SimpleEducationLevelEnumFilter>;
  favouriteUsers?: InputMaybe<Scalars['Boolean']>;
  favouritedBy?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<SimpleIntFilter>;
  lastSeen?: InputMaybe<SimpleDateFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  princessDating?: InputMaybe<SimpleBooleanFilter>;
  rateLevel?: InputMaybe<SimpleUserRateLevelEnumFilter>;
  role?: InputMaybe<SimpleUserRoleEnumFilter>;
  searchTarget?: InputMaybe<SimpleSearchTargetEnumFilter>;
  voice?: InputMaybe<SimpleUserVerifyEnumFilter>;
  weight?: InputMaybe<SimpleIntFilter>;
};

export type SimpleAppNameEnumFilter = {
  equals?: InputMaybe<AppNameEnum>;
  in?: InputMaybe<Array<AppNameEnum>>;
};

export type SimpleBooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
};

export type SimpleCreditActionEnumFilter = {
  equals?: InputMaybe<CreditActionEnum>;
  in?: InputMaybe<Array<CreditActionEnum>>;
};

export type SimpleDatatypeEnumFilter = {
  equals?: InputMaybe<DatatypeEnum>;
  in?: InputMaybe<Array<DatatypeEnum>>;
};

export type SimpleDateFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
};

export type SimpleEducationLevelEnumFilter = {
  equals?: InputMaybe<EducationLevelEnum>;
  in?: InputMaybe<Array<EducationLevelEnum>>;
};

export type SimpleFileStatusEnumFilter = {
  equals?: InputMaybe<FileStatusEnum>;
  in?: InputMaybe<Array<FileStatusEnum>>;
};

export type SimpleGenderEnumFilter = {
  equals?: InputMaybe<GenderEnum>;
  in?: InputMaybe<Array<GenderEnum>>;
};

export type SimpleIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
};

export type SimpleIsAdultEnumFilter = {
  equals?: InputMaybe<IsAdultEnum>;
  in?: InputMaybe<Array<IsAdultEnum>>;
};

export type SimpleLogTypeEnumFilter = {
  equals?: InputMaybe<LogTypeEnum>;
  in?: InputMaybe<Array<LogTypeEnum>>;
};

export type SimpleMessageStatusEnumFilter = {
  equals?: InputMaybe<MessageStatusEnum>;
  in?: InputMaybe<Array<MessageStatusEnum>>;
};

export type SimplePaymentMethodEnumFilter = {
  equals?: InputMaybe<PaymentMethodEnum>;
  in?: InputMaybe<Array<PaymentMethodEnum>>;
};

export type SimplePaymentTypeEnumFilter = {
  equals?: InputMaybe<PaymentTypeEnum>;
  in?: InputMaybe<Array<PaymentTypeEnum>>;
};

export type SimplePermissionEnumFilter = {
  equals?: InputMaybe<PermissionEnum>;
  in?: InputMaybe<Array<PermissionEnum>>;
};

export type SimpleReportCategoryFilter = {
  equals?: InputMaybe<ReportCategory>;
  in?: InputMaybe<Array<ReportCategory>>;
};

export type SimpleReportStatusEnumFilter = {
  equals?: InputMaybe<ReportStatusEnum>;
  in?: InputMaybe<Array<ReportStatusEnum>>;
};

export type SimpleRestrictedLevelEnumFilter = {
  equals?: InputMaybe<RestrictedLevelEnum>;
  in?: InputMaybe<Array<RestrictedLevelEnum>>;
};

export type SimpleSearchTargetEnumFilter = {
  equals?: InputMaybe<SearchTargetEnum>;
  in?: InputMaybe<Array<SearchTargetEnum>>;
};

export type SimpleStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SimpleSubscriptionPlanEnumFilter = {
  equals?: InputMaybe<SubscriptionPlanEnum>;
  in?: InputMaybe<Array<SubscriptionPlanEnum>>;
};

export type SimpleSubscriptionStatusEnumFilter = {
  equals?: InputMaybe<SubscriptionStatusEnum>;
  in?: InputMaybe<Array<SubscriptionStatusEnum>>;
};

export type SimpleTicketStatusEnumFilter = {
  equals?: InputMaybe<TicketStatusEnum>;
  in?: InputMaybe<Array<TicketStatusEnum>>;
};

export type SimpleTicketTypeEnumFilter = {
  equals?: InputMaybe<TicketTypeEnum>;
  in?: InputMaybe<Array<TicketTypeEnum>>;
};

export type SimpleUserAboutBodyEnumFilter = {
  equals?: InputMaybe<UserAboutBodyEnum>;
  in?: InputMaybe<Array<UserAboutBodyEnum>>;
};

export type SimpleUserAboutDrinkEnumFilter = {
  equals?: InputMaybe<UserAboutDrinkEnum>;
  in?: InputMaybe<Array<UserAboutDrinkEnum>>;
};

export type SimpleUserAboutEconomyEnumFilter = {
  equals?: InputMaybe<UserAboutEconomyEnum>;
  in?: InputMaybe<Array<UserAboutEconomyEnum>>;
};

export type SimpleUserAboutHeightEnumFilter = {
  equals?: InputMaybe<UserAboutHeightEnum>;
  in?: InputMaybe<Array<UserAboutHeightEnum>>;
};

export type SimpleUserAboutSmokeEnumFilter = {
  equals?: InputMaybe<UserAboutSmokeEnum>;
  in?: InputMaybe<Array<UserAboutSmokeEnum>>;
};

export type SimpleUserAboutWaistEnumFilter = {
  equals?: InputMaybe<UserAboutWaistEnum>;
  in?: InputMaybe<Array<UserAboutWaistEnum>>;
};

export type SimpleUserContactPrivacyEnumFilter = {
  equals?: InputMaybe<UserContactPrivacyEnum>;
  in?: InputMaybe<Array<UserContactPrivacyEnum>>;
};

export type SimpleUserDateExpenseEnumFilter = {
  equals?: InputMaybe<UserDateExpenseEnum>;
  in?: InputMaybe<Array<UserDateExpenseEnum>>;
};

export type SimpleUserFavStatusEnumFilter = {
  equals?: InputMaybe<UserFavStatusEnum>;
  in?: InputMaybe<Array<UserFavStatusEnum>>;
};

export type SimpleUserFriendStatusEnumFilter = {
  equals?: InputMaybe<UserFriendStatusEnum>;
  in?: InputMaybe<Array<UserFriendStatusEnum>>;
};

export type SimpleUserObjectKeyEnumFilter = {
  equals?: InputMaybe<UserObjectKeyEnum>;
  in?: InputMaybe<Array<UserObjectKeyEnum>>;
};

export type SimpleUserRateLevelEnumFilter = {
  equals?: InputMaybe<UserRateLevelEnum>;
  in?: InputMaybe<Array<UserRateLevelEnum>>;
};

export type SimpleUserRoleEnumFilter = {
  equals?: InputMaybe<UserRoleEnum>;
  in?: InputMaybe<Array<UserRoleEnum>>;
};

export type SimpleUserStatusEnumFilter = {
  equals?: InputMaybe<UserStatusEnum>;
  in?: InputMaybe<Array<UserStatusEnum>>;
};

export type SimpleUserVerifyEnumFilter = {
  equals?: InputMaybe<UserVerifyEnum>;
  in?: InputMaybe<Array<UserVerifyEnum>>;
};

export type SimpleWineReturnStatusEnumFilter = {
  equals?: InputMaybe<WineReturnStatusEnum>;
  in?: InputMaybe<Array<WineReturnStatusEnum>>;
};

export enum SimplifiedUserStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  amount: Scalars['Int'];
  createDt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['Int'];
  lastModifyDt: Scalars['DateTime'];
  nextChargeDt: Scalars['DateTime'];
  paymentLog?: Maybe<PaymentLog>;
  paymentType: Scalars['Int'];
  plan: Scalars['String'];
  status: Scalars['Int'];
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  token: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

export type SubscriptionCreateManyUserInput = {
  amount: Scalars['Int'];
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt: Scalars['DateTime'];
  paymentType: Scalars['Int'];
  plan: Scalars['String'];
  status: Scalars['Int'];
  token: Scalars['String'];
};

export type SubscriptionCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<SubscriptionCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SubscriptionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SubscriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyUserInputEnvelope>;
};

export type SubscriptionCreateNestedOneWithoutPaymentLogsInput = {
  connect?: InputMaybe<SubscriptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<SubscriptionCreateWithoutPaymentLogsInput>;
};

export type SubscriptionCreateNestedOneWithoutPremiumQuotaLogsInput = {
  connect?: InputMaybe<SubscriptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionCreateOrConnectWithoutPremiumQuotaLogsInput>;
  create?: InputMaybe<SubscriptionCreateWithoutPremiumQuotaLogsInput>;
};

export type SubscriptionCreateOrConnectWithoutPaymentLogsInput = {
  create: SubscriptionCreateWithoutPaymentLogsInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionCreateOrConnectWithoutPremiumQuotaLogsInput = {
  create: SubscriptionCreateWithoutPremiumQuotaLogsInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionCreateOrConnectWithoutUserInput = {
  create: SubscriptionCreateWithoutUserInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionCreateWithoutPaymentLogsInput = {
  amount: Scalars['Int'];
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt: Scalars['DateTime'];
  paymentType: Scalars['Int'];
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogCreateNestedManyWithoutSubscriptionInput>;
  status: Scalars['Int'];
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutSubscriptionsInput;
  token: Scalars['String'];
  user?: InputMaybe<UserCreateNestedOneWithoutSubscriptionsInput>;
};

export type SubscriptionCreateWithoutPremiumQuotaLogsInput = {
  amount: Scalars['Int'];
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt: Scalars['DateTime'];
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutSubscriptionInput>;
  paymentType: Scalars['Int'];
  status: Scalars['Int'];
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutSubscriptionsInput;
  token: Scalars['String'];
  user?: InputMaybe<UserCreateNestedOneWithoutSubscriptionsInput>;
};

export type SubscriptionCreateWithoutUserInput = {
  amount: Scalars['Int'];
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt: Scalars['DateTime'];
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutSubscriptionInput>;
  paymentType: Scalars['Int'];
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogCreateNestedManyWithoutSubscriptionInput>;
  status: Scalars['Int'];
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutSubscriptionsInput;
  token: Scalars['String'];
};

export type SubscriptionPaymentLog = {
  __typename?: 'SubscriptionPaymentLog';
  amount: Scalars['Int'];
  billingAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creditCardBrand?: Maybe<Scalars['String']>;
  creditCardLastFour?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  refTxnId?: Maybe<Scalars['String']>;
  subscription: SubscriptionPaymentLogSubscription;
  subscriptionId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export type SubscriptionPaymentLogSubscription = {
  __typename?: 'SubscriptionPaymentLogSubscription';
  id: Scalars['Int'];
  nextChargeDt: Scalars['DateTime'];
  status: Scalars['Int'];
  subscriptionPlan: SubscriptionPlan;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  activeDays: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  frdQuota: Scalars['Int'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isPrincess: Scalars['Boolean'];
  nonFriendMsgQuota: Scalars['Int'];
  role: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionPlanCreateNestedOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutSubscriptionsInput>;
};

export type SubscriptionPlanCreateOrConnectWithoutSubscriptionsInput = {
  create: SubscriptionPlanCreateWithoutSubscriptionsInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateWithoutSubscriptionsInput = {
  activeDays: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota: Scalars['Int'];
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isPrincess?: InputMaybe<Scalars['Boolean']>;
  nonFriendMsgQuota: Scalars['Int'];
  price?: InputMaybe<Scalars['Int']>;
  role: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum SubscriptionPlanEnum {
  HqMember = 'HQ_MEMBER',
  HLvAug2021 = 'H_LV_AUG2021',
  HLvJun2023 = 'H_LV_JUN2023',
  HLvJun2024 = 'H_LV_JUN2024',
  HLvMember_60D = 'H_LV_MEMBER_60D',
  HLvMember_90D = 'H_LV_MEMBER_90D',
  HLvMember_2021 = 'H_LV_MEMBER_2021',
  HLvOct2021 = 'H_LV_OCT2021',
  MbbPremium_3Apr_2024 = 'MBB_PREMIUM_3_APR_2024',
  MbbPremium_6Apr_2024 = 'MBB_PREMIUM_6_APR_2024',
  MbbSilver_3Apr_2024 = 'MBB_SILVER_3_APR_2024',
  MbbSilver_6Apr_2024 = 'MBB_SILVER_6_APR_2024',
  Premium_60D = 'PREMIUM_60D',
  Premium_90D = 'PREMIUM_90D',
  Premium_2021 = 'PREMIUM_2021',
  PremiumAug2021 = 'PREMIUM_AUG2021',
  PremiumJun2023 = 'PREMIUM_JUN2023',
  PremiumJun2024 = 'PREMIUM_JUN2024',
  PremiumOct2021 = 'PREMIUM_OCT2021'
}

export type SubscriptionPlanUpdateOneRequiredWithoutSubscriptionsInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutSubscriptionsInput>;
  update?: InputMaybe<SubscriptionPlanUpdateWithoutSubscriptionsInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutSubscriptionsInput>;
};

export type SubscriptionPlanUpdateWithoutSubscriptionsInput = {
  activeDays?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isPrincess?: InputMaybe<Scalars['Boolean']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionPlanUpsertWithoutSubscriptionsInput = {
  create: SubscriptionPlanCreateWithoutSubscriptionsInput;
  update: SubscriptionPlanUpdateWithoutSubscriptionsInput;
};

export type SubscriptionPlanWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  createDt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastModifyDt?: InputMaybe<DateTimeFilter>;
  nextChargeDt?: InputMaybe<DateTimeFilter>;
  paymentType?: InputMaybe<IntFilter>;
  plan?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  token?: InputMaybe<StringFilter>;
  userId?: InputMaybe<IntFilter>;
};

export enum SubscriptionStatusEnum {
  Active = 'ACTIVE',
  InactiveByAdmin = 'INACTIVE_BY_ADMIN',
  InactiveBySystem = 'INACTIVE_BY_SYSTEM',
  InactiveByUser = 'INACTIVE_BY_USER',
  Nonrebill = 'NONREBILL'
}

export type SubscriptionUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Int']>;
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt?: InputMaybe<Scalars['DateTime']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};

export type SubscriptionUpdateManyWithWhereWithoutUserInput = {
  data: SubscriptionUpdateManyMutationInput;
  where: SubscriptionScalarWhereInput;
};

export type SubscriptionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SubscriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<SubscriptionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<SubscriptionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<SubscriptionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type SubscriptionUpdateOneWithoutPaymentLogsInput = {
  connect?: InputMaybe<SubscriptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<SubscriptionCreateWithoutPaymentLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SubscriptionUpdateWithoutPaymentLogsInput>;
  upsert?: InputMaybe<SubscriptionUpsertWithoutPaymentLogsInput>;
};

export type SubscriptionUpdateOneWithoutPremiumQuotaLogsInput = {
  connect?: InputMaybe<SubscriptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionCreateOrConnectWithoutPremiumQuotaLogsInput>;
  create?: InputMaybe<SubscriptionCreateWithoutPremiumQuotaLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SubscriptionUpdateWithoutPremiumQuotaLogsInput>;
  upsert?: InputMaybe<SubscriptionUpsertWithoutPremiumQuotaLogsInput>;
};

export type SubscriptionUpdateWithWhereUniqueWithoutUserInput = {
  data: SubscriptionUpdateWithoutUserInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpdateWithoutPaymentLogsInput = {
  amount?: InputMaybe<Scalars['Int']>;
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt?: InputMaybe<Scalars['DateTime']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogUpdateManyWithoutSubscriptionInput>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutSubscriptionsInput>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneWithoutSubscriptionsInput>;
};

export type SubscriptionUpdateWithoutPremiumQuotaLogsInput = {
  amount?: InputMaybe<Scalars['Int']>;
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt?: InputMaybe<Scalars['DateTime']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutSubscriptionInput>;
  paymentType?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutSubscriptionsInput>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneWithoutSubscriptionsInput>;
};

export type SubscriptionUpdateWithoutUserInput = {
  amount?: InputMaybe<Scalars['Int']>;
  createDt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  lastModifyDt?: InputMaybe<Scalars['DateTime']>;
  nextChargeDt?: InputMaybe<Scalars['DateTime']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutSubscriptionInput>;
  paymentType?: InputMaybe<Scalars['Int']>;
  premiumQuotaLogs?: InputMaybe<PremiumQuotaLogUpdateManyWithoutSubscriptionInput>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutSubscriptionsInput>;
  token?: InputMaybe<Scalars['String']>;
};

export type SubscriptionUpsertWithWhereUniqueWithoutUserInput = {
  create: SubscriptionCreateWithoutUserInput;
  update: SubscriptionUpdateWithoutUserInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpsertWithoutPaymentLogsInput = {
  create: SubscriptionCreateWithoutPaymentLogsInput;
  update: SubscriptionUpdateWithoutPaymentLogsInput;
};

export type SubscriptionUpsertWithoutPremiumQuotaLogsInput = {
  create: SubscriptionCreateWithoutPremiumQuotaLogsInput;
  update: SubscriptionUpdateWithoutPremiumQuotaLogsInput;
};

export type SubscriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export type TicketCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutTicketsInput;
};

export type TicketCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TicketCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<TicketCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TicketCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TicketWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TicketCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TicketCreateWithoutUserInput>>;
  createMany?: InputMaybe<TicketCreateManyUserInputEnvelope>;
};

export type TicketCreateOrConnectWithoutUserInput = {
  create: TicketCreateWithoutUserInput;
  where: TicketWhereUniqueInput;
};

export type TicketCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TicketOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type TicketScalarWhereInput = {
  AND?: InputMaybe<Array<TicketScalarWhereInput>>;
  NOT?: InputMaybe<Array<TicketScalarWhereInput>>;
  OR?: InputMaybe<Array<TicketScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export enum TicketStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Proceeded = 'PROCEEDED'
}

export enum TicketTypeEnum {
  Admin = 'ADMIN',
  User = 'USER'
}

export type TicketUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTicketsInput>;
};

export type TicketUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TicketUpdateManyWithWhereWithoutUserInput = {
  data: TicketUpdateManyMutationInput;
  where: TicketScalarWhereInput;
};

export type TicketUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<TicketWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TicketCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TicketCreateWithoutUserInput>>;
  createMany?: InputMaybe<TicketCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TicketWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TicketScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TicketWhereUniqueInput>>;
  set?: InputMaybe<Array<TicketWhereUniqueInput>>;
  update?: InputMaybe<Array<TicketUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<TicketUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<TicketUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TicketUpdateWithWhereUniqueWithoutUserInput = {
  data: TicketUpdateWithoutUserInput;
  where: TicketWhereUniqueInput;
};

export type TicketUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TicketUpsertWithWhereUniqueWithoutUserInput = {
  create: TicketCreateWithoutUserInput;
  update: TicketUpdateWithoutUserInput;
  where: TicketWhereUniqueInput;
};

export type TicketWhereInput = {
  AND?: InputMaybe<Array<TicketWhereInput>>;
  NOT?: InputMaybe<Array<TicketWhereInput>>;
  OR?: InputMaybe<Array<TicketWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<IntFilter>;
};

export type TicketWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UpdateOneReportImageInput = {
  imageUrl: Scalars['String'];
};

export type UpdateRateLevelPayload = {
  __typename?: 'UpdateRateLevelPayload';
  user?: Maybe<User>;
};

export type UpdateUserCloneGroupInput = {
  cloneUserId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UpdateUserCreditInput = {
  credit?: InputMaybe<Scalars['Int']>;
  currentCredit?: InputMaybe<Scalars['Int']>;
  currentFrdQuota?: InputMaybe<Scalars['Int']>;
  currentNonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type UpgradeOneUserInput = {
  imageUrl: Scalars['String'];
  planId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  aboutBody?: Maybe<Scalars['Int']>;
  aboutDrink?: Maybe<Scalars['Int']>;
  aboutEconomy?: Maybe<Scalars['Int']>;
  aboutHeight?: Maybe<Scalars['Int']>;
  aboutMe?: Maybe<Scalars['String']>;
  aboutSmoke?: Maybe<Scalars['Int']>;
  aboutStyle?: Maybe<Scalars['Int']>;
  aboutWaist?: Maybe<Scalars['Int']>;
  adminRemark?: Maybe<Scalars['String']>;
  adminRemarkImages?: Maybe<Array<UserAdminRemarkImage>>;
  adminRemarkUpdatedAt?: Maybe<Scalars['DateTime']>;
  ageMax?: Maybe<Scalars['Int']>;
  ageMin?: Maybe<Scalars['Int']>;
  ageVerify: Scalars['Int'];
  avatar?: Maybe<Scalars['String']>;
  avatarBanned: Scalars['Boolean'];
  avatarFull?: Maybe<Scalars['String']>;
  avatarHalf?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactPrivacy?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  credit: Scalars['Int'];
  dateExpense?: Maybe<Scalars['Int']>;
  dateOutline?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  emptyWine: Scalars['Int'];
  frdQuota: Scalars['Int'];
  gender: GenderEnum;
  id: Scalars['Int'];
  lang?: Maybe<Scalars['String']>;
  lastIpv4?: Maybe<Scalars['String']>;
  lastSeen: Scalars['DateTime'];
  line?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoFull?: Maybe<Scalars['String']>;
  logoHalf?: Maybe<Scalars['String']>;
  millionbb?: Maybe<Scalars['Boolean']>;
  minFrdWine?: Maybe<Scalars['Int']>;
  msgQuota: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
  nonFriendMsgQuota: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  premiumEndDt: Scalars['DateTime'];
  princessDating?: Maybe<Scalars['Boolean']>;
  rateLevel: Scalars['Int'];
  rateSummary: RateSummary;
  refId?: Maybe<Scalars['String']>;
  restrictedLevel: Scalars['Int'];
  role: Scalars['Int'];
  silverEndDt: Scalars['DateTime'];
  status: Scalars['Int'];
  telegram?: Maybe<Scalars['String']>;
  totalFriends: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  vip: Scalars['Int'];
  voice: Scalars['Int'];
  wechat?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  wine: Scalars['Int'];
};

export enum UserAboutBodyEnum {
  Athletic = 'ATHLETIC',
  Normal = 'NORMAL',
  Other = 'OTHER',
  Stout = 'STOUT',
  Superfluous = 'SUPERFLUOUS',
  Thin = 'THIN'
}

export enum UserAboutDrinkEnum {
  Never = 'NEVER',
  Sometime = 'SOMETIME',
  Usually = 'USUALLY'
}

export enum UserAboutEconomyEnum {
  LotSpare = 'LOT_SPARE',
  NotEnough = 'NOT_ENOUGH',
  SometimeNo = 'SOMETIME_NO',
  SomeSpare = 'SOME_SPARE',
  Sufficient = 'SUFFICIENT',
  TooMuch = 'TOO_MUCH',
  Urgent = 'URGENT'
}

export enum UserAboutHeightEnum {
  Cm150 = 'CM150',
  Cm151 = 'CM151',
  Cm156 = 'CM156',
  Cm161 = 'CM161',
  Cm166 = 'CM166',
  Cm171 = 'CM171',
  Cm181 = 'CM181',
  Cm191 = 'CM191',
  Cm200 = 'CM200'
}

export enum UserAboutSmokeEnum {
  Always = 'ALWAYS',
  Never = 'NEVER',
  Sometime = 'SOMETIME'
}

export enum UserAboutWaistEnum {
  In19 = 'IN19',
  In20 = 'IN20',
  In23 = 'IN23',
  In26 = 'IN26',
  In29 = 'IN29',
  In32 = 'IN32',
  In36 = 'IN36',
  In41 = 'IN41',
  In51 = 'IN51',
  In60 = 'IN60'
}

export type UserAdminRemark = {
  __typename?: 'UserAdminRemark';
  images?: Maybe<Array<UserAdminRemarkImage>>;
  message?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAdminRemarkImage = {
  __typename?: 'UserAdminRemarkImage';
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
};

export type UserAdminRemarkImageCreateManyUserInput = {
  id?: InputMaybe<Scalars['Int']>;
  imageUrl: Scalars['String'];
};

export type UserAdminRemarkImageCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserAdminRemarkImageCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserAdminRemarkImageCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserAdminRemarkImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAdminRemarkImageCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserAdminRemarkImageCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserAdminRemarkImageCreateManyUserInputEnvelope>;
};

export type UserAdminRemarkImageCreateOrConnectWithoutUserInput = {
  create: UserAdminRemarkImageCreateWithoutUserInput;
  where: UserAdminRemarkImageWhereUniqueInput;
};

export type UserAdminRemarkImageCreateWithoutUserInput = {
  imageUrl: Scalars['String'];
};

export type UserAdminRemarkImageScalarWhereInput = {
  AND?: InputMaybe<Array<UserAdminRemarkImageScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserAdminRemarkImageScalarWhereInput>>;
  OR?: InputMaybe<Array<UserAdminRemarkImageScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserAdminRemarkImageUpdateManyMutationInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UserAdminRemarkImageUpdateManyWithWhereWithoutUserInput = {
  data: UserAdminRemarkImageUpdateManyMutationInput;
  where: UserAdminRemarkImageScalarWhereInput;
};

export type UserAdminRemarkImageUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserAdminRemarkImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAdminRemarkImageCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserAdminRemarkImageCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserAdminRemarkImageCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserAdminRemarkImageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserAdminRemarkImageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserAdminRemarkImageWhereUniqueInput>>;
  set?: InputMaybe<Array<UserAdminRemarkImageWhereUniqueInput>>;
  update?: InputMaybe<Array<UserAdminRemarkImageUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserAdminRemarkImageUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserAdminRemarkImageUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserAdminRemarkImageUpdateWithWhereUniqueWithoutUserInput = {
  data: UserAdminRemarkImageUpdateWithoutUserInput;
  where: UserAdminRemarkImageWhereUniqueInput;
};

export type UserAdminRemarkImageUpdateWithoutUserInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UserAdminRemarkImageUpsertWithWhereUniqueWithoutUserInput = {
  create: UserAdminRemarkImageCreateWithoutUserInput;
  update: UserAdminRemarkImageUpdateWithoutUserInput;
  where: UserAdminRemarkImageWhereUniqueInput;
};

export type UserAdminRemarkImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserBlacklist = {
  __typename?: 'UserBlacklist';
  blacklistedUser: User;
  blacklistedUserId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  isBlacklisted: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export type UserBlacklistCreateInput = {
  blacklistedUser: UserCreateNestedOneWithoutBlacklistedUserblacklistsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserBlacklistsInput;
};

export type UserBlacklistCreateManyBlacklistedUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export type UserBlacklistCreateManyBlacklistedUserInputEnvelope = {
  data?: InputMaybe<Array<UserBlacklistCreateManyBlacklistedUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserBlacklistCreateManyUserInput = {
  blacklistedUserId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserBlacklistCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserBlacklistCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserBlacklistCreateNestedManyWithoutBlacklistedUserInput = {
  connect?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBlacklistCreateOrConnectWithoutBlacklistedUserInput>>;
  create?: InputMaybe<Array<UserBlacklistCreateWithoutBlacklistedUserInput>>;
  createMany?: InputMaybe<UserBlacklistCreateManyBlacklistedUserInputEnvelope>;
};

export type UserBlacklistCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBlacklistCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserBlacklistCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserBlacklistCreateManyUserInputEnvelope>;
};

export type UserBlacklistCreateOrConnectWithoutBlacklistedUserInput = {
  create: UserBlacklistCreateWithoutBlacklistedUserInput;
  where: UserBlacklistWhereUniqueInput;
};

export type UserBlacklistCreateOrConnectWithoutUserInput = {
  create: UserBlacklistCreateWithoutUserInput;
  where: UserBlacklistWhereUniqueInput;
};

export type UserBlacklistCreateWithoutBlacklistedUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserBlacklistsInput;
};

export type UserBlacklistCreateWithoutUserInput = {
  blacklistedUser: UserCreateNestedOneWithoutBlacklistedUserblacklistsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserBlacklistOrderByWithRelationInput = {
  blacklistedUser?: InputMaybe<UserOrderByWithRelationInput>;
  blacklistedUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isBlacklisted?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserBlacklistScalarWhereInput = {
  AND?: InputMaybe<Array<UserBlacklistScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserBlacklistScalarWhereInput>>;
  OR?: InputMaybe<Array<UserBlacklistScalarWhereInput>>;
  blacklistedUserId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isBlacklisted?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserBlacklistUpdateInput = {
  blacklistedUser?: InputMaybe<UserUpdateOneRequiredWithoutBlacklistedUserblacklistsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserBlacklistsInput>;
};

export type UserBlacklistUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserBlacklistUpdateManyWithWhereWithoutBlacklistedUserInput = {
  data: UserBlacklistUpdateManyMutationInput;
  where: UserBlacklistScalarWhereInput;
};

export type UserBlacklistUpdateManyWithWhereWithoutUserInput = {
  data: UserBlacklistUpdateManyMutationInput;
  where: UserBlacklistScalarWhereInput;
};

export type UserBlacklistUpdateManyWithoutBlacklistedUserInput = {
  connect?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBlacklistCreateOrConnectWithoutBlacklistedUserInput>>;
  create?: InputMaybe<Array<UserBlacklistCreateWithoutBlacklistedUserInput>>;
  createMany?: InputMaybe<UserBlacklistCreateManyBlacklistedUserInputEnvelope>;
  delete?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserBlacklistScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  set?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  update?: InputMaybe<Array<UserBlacklistUpdateWithWhereUniqueWithoutBlacklistedUserInput>>;
  updateMany?: InputMaybe<Array<UserBlacklistUpdateManyWithWhereWithoutBlacklistedUserInput>>;
  upsert?: InputMaybe<Array<UserBlacklistUpsertWithWhereUniqueWithoutBlacklistedUserInput>>;
};

export type UserBlacklistUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBlacklistCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserBlacklistCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserBlacklistCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserBlacklistScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  set?: InputMaybe<Array<UserBlacklistWhereUniqueInput>>;
  update?: InputMaybe<Array<UserBlacklistUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserBlacklistUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserBlacklistUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserBlacklistUpdateWithWhereUniqueWithoutBlacklistedUserInput = {
  data: UserBlacklistUpdateWithoutBlacklistedUserInput;
  where: UserBlacklistWhereUniqueInput;
};

export type UserBlacklistUpdateWithWhereUniqueWithoutUserInput = {
  data: UserBlacklistUpdateWithoutUserInput;
  where: UserBlacklistWhereUniqueInput;
};

export type UserBlacklistUpdateWithoutBlacklistedUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserBlacklistsInput>;
};

export type UserBlacklistUpdateWithoutUserInput = {
  blacklistedUser?: InputMaybe<UserUpdateOneRequiredWithoutBlacklistedUserblacklistsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserBlacklistUpsertWithWhereUniqueWithoutBlacklistedUserInput = {
  create: UserBlacklistCreateWithoutBlacklistedUserInput;
  update: UserBlacklistUpdateWithoutBlacklistedUserInput;
  where: UserBlacklistWhereUniqueInput;
};

export type UserBlacklistUpsertWithWhereUniqueWithoutUserInput = {
  create: UserBlacklistCreateWithoutUserInput;
  update: UserBlacklistUpdateWithoutUserInput;
  where: UserBlacklistWhereUniqueInput;
};

export type UserBlacklistWhereInput = {
  AND?: InputMaybe<Array<UserBlacklistWhereInput>>;
  NOT?: InputMaybe<Array<UserBlacklistWhereInput>>;
  OR?: InputMaybe<Array<UserBlacklistWhereInput>>;
  blacklistedUser?: InputMaybe<UserWhereInput>;
  blacklistedUserId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isBlacklisted?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<IntFilter>;
};

export type UserBlacklistWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum UserContactPrivacyEnum {
  AllPaid = 'ALL_PAID',
  NoOne = 'NO_ONE',
  OnlyPremium = 'ONLY_PREMIUM'
}

export type UserCreateNestedOneWithoutAlbumImagesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAlbumImagesInput>;
  create?: InputMaybe<UserCreateWithoutAlbumImagesInput>;
};

export type UserCreateNestedOneWithoutBlacklistedUserblacklistsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBlacklistedUserblacklistsInput>;
  create?: InputMaybe<UserCreateWithoutBlacklistedUserblacklistsInput>;
};

export type UserCreateNestedOneWithoutCreditLogsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreditLogsInput>;
  create?: InputMaybe<UserCreateWithoutCreditLogsInput>;
};

export type UserCreateNestedOneWithoutFavouriteUsersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavouriteUsersInput>;
  create?: InputMaybe<UserCreateWithoutFavouriteUsersInput>;
};

export type UserCreateNestedOneWithoutFavouritedByInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavouritedByInput>;
  create?: InputMaybe<UserCreateWithoutFavouritedByInput>;
};

export type UserCreateNestedOneWithoutFriendUserFriendsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFriendUserFriendsInput>;
  create?: InputMaybe<UserCreateWithoutFriendUserFriendsInput>;
};

export type UserCreateNestedOneWithoutPaymentLogsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<UserCreateWithoutPaymentLogsInput>;
};

export type UserCreateNestedOneWithoutPaymentMethodCreditCardsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPaymentMethodCreditCardsInput>;
  create?: InputMaybe<UserCreateWithoutPaymentMethodCreditCardsInput>;
};

export type UserCreateNestedOneWithoutReceivedMessagesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceivedMessagesInput>;
  create?: InputMaybe<UserCreateWithoutReceivedMessagesInput>;
};

export type UserCreateNestedOneWithoutReporteeInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReporteeInput>;
  create?: InputMaybe<UserCreateWithoutReporteeInput>;
};

export type UserCreateNestedOneWithoutReporterInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReporterInput>;
  create?: InputMaybe<UserCreateWithoutReporterInput>;
};

export type UserCreateNestedOneWithoutSentMessagesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentMessagesInput>;
  create?: InputMaybe<UserCreateWithoutSentMessagesInput>;
};

export type UserCreateNestedOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<UserCreateWithoutSubscriptionsInput>;
};

export type UserCreateNestedOneWithoutTicketsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTicketsInput>;
  create?: InputMaybe<UserCreateWithoutTicketsInput>;
};

export type UserCreateNestedOneWithoutUserBlacklistsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserBlacklistsInput>;
  create?: InputMaybe<UserCreateWithoutUserBlacklistsInput>;
};

export type UserCreateNestedOneWithoutUserFriendsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserFriendsInput>;
  create?: InputMaybe<UserCreateWithoutUserFriendsInput>;
};

export type UserCreateNestedOneWithoutViewedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutViewedInput>;
  create?: InputMaybe<UserCreateWithoutViewedInput>;
};

export type UserCreateNestedOneWithoutViewersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutViewersInput>;
  create?: InputMaybe<UserCreateWithoutViewersInput>;
};

export type UserCreateOrConnectWithoutAlbumImagesInput = {
  create: UserCreateWithoutAlbumImagesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBlacklistedUserblacklistsInput = {
  create: UserCreateWithoutBlacklistedUserblacklistsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreditLogsInput = {
  create: UserCreateWithoutCreditLogsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavouriteUsersInput = {
  create: UserCreateWithoutFavouriteUsersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavouritedByInput = {
  create: UserCreateWithoutFavouritedByInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFriendUserFriendsInput = {
  create: UserCreateWithoutFriendUserFriendsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPaymentLogsInput = {
  create: UserCreateWithoutPaymentLogsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPaymentMethodCreditCardsInput = {
  create: UserCreateWithoutPaymentMethodCreditCardsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReceivedMessagesInput = {
  create: UserCreateWithoutReceivedMessagesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReporteeInput = {
  create: UserCreateWithoutReporteeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReporterInput = {
  create: UserCreateWithoutReporterInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSentMessagesInput = {
  create: UserCreateWithoutSentMessagesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSubscriptionsInput = {
  create: UserCreateWithoutSubscriptionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTicketsInput = {
  create: UserCreateWithoutTicketsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserBlacklistsInput = {
  create: UserCreateWithoutUserBlacklistsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserFriendsInput = {
  create: UserCreateWithoutUserFriendsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutViewedInput = {
  create: UserCreateWithoutViewedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutViewersInput = {
  create: UserCreateWithoutViewersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAlbumImagesInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutBlacklistedUserblacklistsInput = {
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutCreditLogsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutFavouriteUsersInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutFavouritedByInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutFriendUserFriendsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutPaymentLogsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutPaymentMethodCreditCardsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutReceivedMessagesInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutReporteeInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutReporterInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutSentMessagesInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutSubscriptionsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutTicketsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutUserBlacklistsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutUserFriendsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutViewedInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewers?: InputMaybe<UserViewLogCreateNestedManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserCreateWithoutViewersInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageCreateNestedManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteCreateNestedManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteCreateNestedManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendCreateNestedManyWithoutFriendUserInput>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageCreateNestedManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportCreateNestedManyWithoutUserInput>;
  reporter?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageCreateNestedManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketCreateNestedManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistCreateNestedManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogCreateNestedManyWithoutUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export enum UserDateExpenseEnum {
  AssumeAll = 'ASSUME_ALL',
  DoesntMatter = 'DOESNT_MATTER',
  ExpectOther = 'EXPECT_OTHER',
  FemalePay = 'FEMALE_PAY',
  MalePay = 'MALE_PAY',
  Split = 'SPLIT'
}

export enum UserFavStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserFavouriteCreateManyFavouriteUserInput = {
  create_dt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  user_id: Scalars['Int'];
};

export type UserFavouriteCreateManyFavouriteUserInputEnvelope = {
  data?: InputMaybe<Array<UserFavouriteCreateManyFavouriteUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserFavouriteCreateManyUserInput = {
  create_dt?: InputMaybe<Scalars['DateTime']>;
  favourite_user_id: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type UserFavouriteCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserFavouriteCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserFavouriteCreateNestedManyWithoutFavouriteUserInput = {
  connect?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFavouriteCreateOrConnectWithoutFavouriteUserInput>>;
  create?: InputMaybe<Array<UserFavouriteCreateWithoutFavouriteUserInput>>;
  createMany?: InputMaybe<UserFavouriteCreateManyFavouriteUserInputEnvelope>;
};

export type UserFavouriteCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFavouriteCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserFavouriteCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserFavouriteCreateManyUserInputEnvelope>;
};

export type UserFavouriteCreateOrConnectWithoutFavouriteUserInput = {
  create: UserFavouriteCreateWithoutFavouriteUserInput;
  where: UserFavouriteWhereUniqueInput;
};

export type UserFavouriteCreateOrConnectWithoutUserInput = {
  create: UserFavouriteCreateWithoutUserInput;
  where: UserFavouriteWhereUniqueInput;
};

export type UserFavouriteCreateWithoutFavouriteUserInput = {
  create_dt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  user: UserCreateNestedOneWithoutFavouriteUsersInput;
};

export type UserFavouriteCreateWithoutUserInput = {
  create_dt?: InputMaybe<Scalars['DateTime']>;
  favouriteUser: UserCreateNestedOneWithoutFavouritedByInput;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type UserFavouriteScalarWhereInput = {
  AND?: InputMaybe<Array<UserFavouriteScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserFavouriteScalarWhereInput>>;
  OR?: InputMaybe<Array<UserFavouriteScalarWhereInput>>;
  create_dt?: InputMaybe<DateTimeFilter>;
  favourite_user_id?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  last_modify_dt?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
  user_id?: InputMaybe<IntFilter>;
};

export type UserFavouriteUpdateManyMutationInput = {
  create_dt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type UserFavouriteUpdateManyWithWhereWithoutFavouriteUserInput = {
  data: UserFavouriteUpdateManyMutationInput;
  where: UserFavouriteScalarWhereInput;
};

export type UserFavouriteUpdateManyWithWhereWithoutUserInput = {
  data: UserFavouriteUpdateManyMutationInput;
  where: UserFavouriteScalarWhereInput;
};

export type UserFavouriteUpdateManyWithoutFavouriteUserInput = {
  connect?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFavouriteCreateOrConnectWithoutFavouriteUserInput>>;
  create?: InputMaybe<Array<UserFavouriteCreateWithoutFavouriteUserInput>>;
  createMany?: InputMaybe<UserFavouriteCreateManyFavouriteUserInputEnvelope>;
  delete?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFavouriteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  update?: InputMaybe<Array<UserFavouriteUpdateWithWhereUniqueWithoutFavouriteUserInput>>;
  updateMany?: InputMaybe<Array<UserFavouriteUpdateManyWithWhereWithoutFavouriteUserInput>>;
  upsert?: InputMaybe<Array<UserFavouriteUpsertWithWhereUniqueWithoutFavouriteUserInput>>;
};

export type UserFavouriteUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFavouriteCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserFavouriteCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserFavouriteCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFavouriteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFavouriteWhereUniqueInput>>;
  update?: InputMaybe<Array<UserFavouriteUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserFavouriteUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserFavouriteUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserFavouriteUpdateWithWhereUniqueWithoutFavouriteUserInput = {
  data: UserFavouriteUpdateWithoutFavouriteUserInput;
  where: UserFavouriteWhereUniqueInput;
};

export type UserFavouriteUpdateWithWhereUniqueWithoutUserInput = {
  data: UserFavouriteUpdateWithoutUserInput;
  where: UserFavouriteWhereUniqueInput;
};

export type UserFavouriteUpdateWithoutFavouriteUserInput = {
  create_dt?: InputMaybe<Scalars['DateTime']>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFavouriteUsersInput>;
};

export type UserFavouriteUpdateWithoutUserInput = {
  create_dt?: InputMaybe<Scalars['DateTime']>;
  favouriteUser?: InputMaybe<UserUpdateOneRequiredWithoutFavouritedByInput>;
  last_modify_dt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type UserFavouriteUpsertWithWhereUniqueWithoutFavouriteUserInput = {
  create: UserFavouriteCreateWithoutFavouriteUserInput;
  update: UserFavouriteUpdateWithoutFavouriteUserInput;
  where: UserFavouriteWhereUniqueInput;
};

export type UserFavouriteUpsertWithWhereUniqueWithoutUserInput = {
  create: UserFavouriteCreateWithoutUserInput;
  update: UserFavouriteUpdateWithoutUserInput;
  where: UserFavouriteWhereUniqueInput;
};

export type UserFavouriteWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserFriend = {
  __typename?: 'UserFriend';
  anonymousDate?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  friendAnonymousDate?: Maybe<Scalars['DateTime']>;
  friendComment?: Maybe<Scalars['String']>;
  friendRate?: Maybe<Scalars['Float']>;
  friendUser: User;
  friendUserId: Scalars['Int'];
  id: Scalars['Int'];
  rate?: Maybe<Scalars['Float']>;
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
  wine: Scalars['Int'];
};

export type UserFriendCreateInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  friendUser: UserCreateNestedOneWithoutFriendUserFriendsInput;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserFriendsInput;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendCreateManyFriendUserInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendCreateManyFriendUserInputEnvelope = {
  data?: InputMaybe<Array<UserFriendCreateManyFriendUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserFriendCreateManyUserInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  friendUserId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserFriendCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserFriendCreateNestedManyWithoutFriendUserInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutFriendUserInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutFriendUserInput>>;
  createMany?: InputMaybe<UserFriendCreateManyFriendUserInputEnvelope>;
};

export type UserFriendCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserFriendCreateManyUserInputEnvelope>;
};

export type UserFriendCreateOrConnectWithoutFriendUserInput = {
  create: UserFriendCreateWithoutFriendUserInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendCreateOrConnectWithoutUserInput = {
  create: UserFriendCreateWithoutUserInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendCreateWithoutFriendUserInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserFriendsInput;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendCreateWithoutUserInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  friendUser: UserCreateNestedOneWithoutFriendUserFriendsInput;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendOrderByWithRelationInput = {
  anonymousDate?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  friendAnonymousDate?: InputMaybe<SortOrder>;
  friendComment?: InputMaybe<SortOrder>;
  friendRate?: InputMaybe<SortOrder>;
  friendUser?: InputMaybe<UserOrderByWithRelationInput>;
  friendUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  wine?: InputMaybe<SortOrder>;
};

export type UserFriendScalarWhereInput = {
  AND?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  OR?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  anonymousDate?: InputMaybe<DateTimeNullableFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  friendAnonymousDate?: InputMaybe<DateTimeNullableFilter>;
  friendComment?: InputMaybe<StringNullableFilter>;
  friendRate?: InputMaybe<FloatNullableFilter>;
  friendUserId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  rate?: InputMaybe<FloatNullableFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
  wine?: InputMaybe<IntFilter>;
};

export enum UserFriendStatusEnum {
  Accepted = 'ACCEPTED',
  ByQuota = 'BY_QUOTA',
  Cancelled = 'CANCELLED',
  None = 'NONE',
  Rejected = 'REJECTED',
  RemovedByAdmin = 'REMOVED_BY_ADMIN',
  Requested = 'REQUESTED',
  Unrespond = 'UNRESPOND'
}

export type UserFriendUpdateInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  friendUser?: InputMaybe<UserUpdateOneRequiredWithoutFriendUserFriendsInput>;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserFriendsInput>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendUpdateManyMutationInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendUpdateManyWithWhereWithoutFriendUserInput = {
  data: UserFriendUpdateManyMutationInput;
  where: UserFriendScalarWhereInput;
};

export type UserFriendUpdateManyWithWhereWithoutUserInput = {
  data: UserFriendUpdateManyMutationInput;
  where: UserFriendScalarWhereInput;
};

export type UserFriendUpdateManyWithoutFriendUserInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutFriendUserInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutFriendUserInput>>;
  createMany?: InputMaybe<UserFriendCreateManyFriendUserInputEnvelope>;
  delete?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  update?: InputMaybe<Array<UserFriendUpdateWithWhereUniqueWithoutFriendUserInput>>;
  updateMany?: InputMaybe<Array<UserFriendUpdateManyWithWhereWithoutFriendUserInput>>;
  upsert?: InputMaybe<Array<UserFriendUpsertWithWhereUniqueWithoutFriendUserInput>>;
};

export type UserFriendUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserFriendCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  update?: InputMaybe<Array<UserFriendUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserFriendUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserFriendUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserFriendUpdateWithWhereUniqueWithoutFriendUserInput = {
  data: UserFriendUpdateWithoutFriendUserInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendUpdateWithWhereUniqueWithoutUserInput = {
  data: UserFriendUpdateWithoutUserInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendUpdateWithoutFriendUserInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserFriendsInput>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendUpdateWithoutUserInput = {
  anonymousDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendAnonymousDate?: InputMaybe<Scalars['DateTime']>;
  friendComment?: InputMaybe<Scalars['String']>;
  friendRate?: InputMaybe<Scalars['Float']>;
  friendUser?: InputMaybe<UserUpdateOneRequiredWithoutFriendUserFriendsInput>;
  rate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserFriendUpsertWithWhereUniqueWithoutFriendUserInput = {
  create: UserFriendCreateWithoutFriendUserInput;
  update: UserFriendUpdateWithoutFriendUserInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendUpsertWithWhereUniqueWithoutUserInput = {
  create: UserFriendCreateWithoutUserInput;
  update: UserFriendUpdateWithoutUserInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendWhereInput = {
  AND?: InputMaybe<Array<UserFriendWhereInput>>;
  NOT?: InputMaybe<Array<UserFriendWhereInput>>;
  OR?: InputMaybe<Array<UserFriendWhereInput>>;
  anonymousDate?: InputMaybe<DateTimeNullableFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  friendAnonymousDate?: InputMaybe<DateTimeNullableFilter>;
  friendComment?: InputMaybe<StringNullableFilter>;
  friendRate?: InputMaybe<FloatNullableFilter>;
  friendUser?: InputMaybe<UserWhereInput>;
  friendUserId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  rate?: InputMaybe<FloatNullableFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<IntFilter>;
  wine?: InputMaybe<IntFilter>;
};

export type UserFriendWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserMessageCreateManyRecipantUserInput = {
  createDt?: InputMaybe<Scalars['DateTime']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  message: Scalars['String'];
  sender: Scalars['Int'];
  senderIpv4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updateDt?: InputMaybe<Scalars['DateTime']>;
};

export type UserMessageCreateManyRecipantUserInputEnvelope = {
  data?: InputMaybe<Array<UserMessageCreateManyRecipantUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserMessageCreateManySenderUserInput = {
  createDt?: InputMaybe<Scalars['DateTime']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  message: Scalars['String'];
  recipant: Scalars['Int'];
  senderIpv4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updateDt?: InputMaybe<Scalars['DateTime']>;
};

export type UserMessageCreateManySenderUserInputEnvelope = {
  data?: InputMaybe<Array<UserMessageCreateManySenderUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserMessageCreateNestedManyWithoutRecipantUserInput = {
  connect?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserMessageCreateOrConnectWithoutRecipantUserInput>>;
  create?: InputMaybe<Array<UserMessageCreateWithoutRecipantUserInput>>;
  createMany?: InputMaybe<UserMessageCreateManyRecipantUserInputEnvelope>;
};

export type UserMessageCreateNestedManyWithoutSenderUserInput = {
  connect?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserMessageCreateOrConnectWithoutSenderUserInput>>;
  create?: InputMaybe<Array<UserMessageCreateWithoutSenderUserInput>>;
  createMany?: InputMaybe<UserMessageCreateManySenderUserInputEnvelope>;
};

export type UserMessageCreateOrConnectWithoutRecipantUserInput = {
  create: UserMessageCreateWithoutRecipantUserInput;
  where: UserMessageWhereUniqueInput;
};

export type UserMessageCreateOrConnectWithoutSenderUserInput = {
  create: UserMessageCreateWithoutSenderUserInput;
  where: UserMessageWhereUniqueInput;
};

export type UserMessageCreateWithoutRecipantUserInput = {
  createDt?: InputMaybe<Scalars['DateTime']>;
  filename?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  senderIpv4?: InputMaybe<Scalars['String']>;
  senderUser?: InputMaybe<UserCreateNestedOneWithoutSentMessagesInput>;
  status?: InputMaybe<Scalars['Int']>;
  updateDt?: InputMaybe<Scalars['DateTime']>;
};

export type UserMessageCreateWithoutSenderUserInput = {
  createDt?: InputMaybe<Scalars['DateTime']>;
  filename?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  recipantUser?: InputMaybe<UserCreateNestedOneWithoutReceivedMessagesInput>;
  senderIpv4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updateDt?: InputMaybe<Scalars['DateTime']>;
};

export type UserMessageScalarWhereInput = {
  AND?: InputMaybe<Array<UserMessageScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserMessageScalarWhereInput>>;
  OR?: InputMaybe<Array<UserMessageScalarWhereInput>>;
  createDt?: InputMaybe<DateTimeFilter>;
  filename?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  message?: InputMaybe<StringFilter>;
  recipant?: InputMaybe<IntFilter>;
  sender?: InputMaybe<IntFilter>;
  senderIpv4?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  updateDt?: InputMaybe<DateTimeFilter>;
};

export type UserMessageUpdateManyMutationInput = {
  createDt?: InputMaybe<Scalars['DateTime']>;
  filename?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  senderIpv4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updateDt?: InputMaybe<Scalars['DateTime']>;
};

export type UserMessageUpdateManyWithWhereWithoutRecipantUserInput = {
  data: UserMessageUpdateManyMutationInput;
  where: UserMessageScalarWhereInput;
};

export type UserMessageUpdateManyWithWhereWithoutSenderUserInput = {
  data: UserMessageUpdateManyMutationInput;
  where: UserMessageScalarWhereInput;
};

export type UserMessageUpdateManyWithoutRecipantUserInput = {
  connect?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserMessageCreateOrConnectWithoutRecipantUserInput>>;
  create?: InputMaybe<Array<UserMessageCreateWithoutRecipantUserInput>>;
  createMany?: InputMaybe<UserMessageCreateManyRecipantUserInputEnvelope>;
  delete?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<UserMessageUpdateWithWhereUniqueWithoutRecipantUserInput>>;
  updateMany?: InputMaybe<Array<UserMessageUpdateManyWithWhereWithoutRecipantUserInput>>;
  upsert?: InputMaybe<Array<UserMessageUpsertWithWhereUniqueWithoutRecipantUserInput>>;
};

export type UserMessageUpdateManyWithoutSenderUserInput = {
  connect?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserMessageCreateOrConnectWithoutSenderUserInput>>;
  create?: InputMaybe<Array<UserMessageCreateWithoutSenderUserInput>>;
  createMany?: InputMaybe<UserMessageCreateManySenderUserInputEnvelope>;
  delete?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<UserMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<UserMessageUpdateWithWhereUniqueWithoutSenderUserInput>>;
  updateMany?: InputMaybe<Array<UserMessageUpdateManyWithWhereWithoutSenderUserInput>>;
  upsert?: InputMaybe<Array<UserMessageUpsertWithWhereUniqueWithoutSenderUserInput>>;
};

export type UserMessageUpdateWithWhereUniqueWithoutRecipantUserInput = {
  data: UserMessageUpdateWithoutRecipantUserInput;
  where: UserMessageWhereUniqueInput;
};

export type UserMessageUpdateWithWhereUniqueWithoutSenderUserInput = {
  data: UserMessageUpdateWithoutSenderUserInput;
  where: UserMessageWhereUniqueInput;
};

export type UserMessageUpdateWithoutRecipantUserInput = {
  createDt?: InputMaybe<Scalars['DateTime']>;
  filename?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  senderIpv4?: InputMaybe<Scalars['String']>;
  senderUser?: InputMaybe<UserUpdateOneWithoutSentMessagesInput>;
  status?: InputMaybe<Scalars['Int']>;
  updateDt?: InputMaybe<Scalars['DateTime']>;
};

export type UserMessageUpdateWithoutSenderUserInput = {
  createDt?: InputMaybe<Scalars['DateTime']>;
  filename?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  recipantUser?: InputMaybe<UserUpdateOneWithoutReceivedMessagesInput>;
  senderIpv4?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updateDt?: InputMaybe<Scalars['DateTime']>;
};

export type UserMessageUpsertWithWhereUniqueWithoutRecipantUserInput = {
  create: UserMessageCreateWithoutRecipantUserInput;
  update: UserMessageUpdateWithoutRecipantUserInput;
  where: UserMessageWhereUniqueInput;
};

export type UserMessageUpsertWithWhereUniqueWithoutSenderUserInput = {
  create: UserMessageCreateWithoutSenderUserInput;
  update: UserMessageUpdateWithoutSenderUserInput;
  where: UserMessageWhereUniqueInput;
};

export type UserMessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserObject = {
  __typename?: 'UserObject';
  createdAt: Scalars['DateTime'];
  data: Scalars['String'];
  datatype: Scalars['Int'];
  id: Scalars['Int'];
  key: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
};

export type UserObjectCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['String'];
  datatype?: InputMaybe<Scalars['Int']>;
  key: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export enum UserObjectKeyEnum {
  AgeVerify = 'AGE_VERIFY',
  AgeVerifyRejectReason = 'AGE_VERIFY_REJECT_REASON',
  AndroidLogin = 'ANDROID_LOGIN',
  AndroidLogout = 'ANDROID_LOGOUT',
  FirebaseLogin = 'FIREBASE_LOGIN',
  FirebaseLogout = 'FIREBASE_LOGOUT',
  IosLogin = 'IOS_LOGIN',
  IosLogout = 'IOS_LOGOUT',
  VoiceIntroRejectReason = 'VOICE_INTRO_REJECT_REASON'
}

export type UserObjectOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  datatype?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserObjectUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['String']>;
  datatype?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type UserObjectUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['String']>;
  datatype?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type UserObjectWhereInput = {
  AND?: InputMaybe<Array<UserObjectWhereInput>>;
  NOT?: InputMaybe<Array<UserObjectWhereInput>>;
  OR?: InputMaybe<Array<UserObjectWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<StringFilter>;
  datatype?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  key?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserObjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserOnboardData = {
  avatar?: InputMaybe<Scalars['String']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  dob: Scalars['Date'];
  gender?: InputMaybe<GenderEnum>;
  line?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
  telegram?: InputMaybe<Scalars['String']>;
  wechat?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type UserOnboardPayload = {
  __typename?: 'UserOnboardPayload';
  idToken: Scalars['String'];
  user: User;
};

export type UserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export enum UserRateLevelEnum {
  Normal = 'NORMAL',
  Premium = 'PREMIUM',
  Verified = 'VERIFIED'
}

export enum UserRoleEnum {
  HLvMember = 'H_LV_MEMBER',
  Normal = 'NORMAL',
  Premium = 'PREMIUM',
  Silver = 'SILVER'
}

export enum UserStatusEnum {
  Banned = 'BANNED',
  Deleted = 'DELETED',
  NotVerify = 'NOT_VERIFY',
  ProfileCompleted = 'PROFILE_COMPLETED',
  Verified = 'VERIFIED'
}

export type UserUpdateInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['Date']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  telegram?: InputMaybe<Scalars['String']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type UserUpdateOneRequiredWithoutAlbumImagesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAlbumImagesInput>;
  create?: InputMaybe<UserCreateWithoutAlbumImagesInput>;
  update?: InputMaybe<UserUpdateWithoutAlbumImagesInput>;
  upsert?: InputMaybe<UserUpsertWithoutAlbumImagesInput>;
};

export type UserUpdateOneRequiredWithoutBlacklistedUserblacklistsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBlacklistedUserblacklistsInput>;
  create?: InputMaybe<UserCreateWithoutBlacklistedUserblacklistsInput>;
  update?: InputMaybe<UserUpdateWithoutBlacklistedUserblacklistsInput>;
  upsert?: InputMaybe<UserUpsertWithoutBlacklistedUserblacklistsInput>;
};

export type UserUpdateOneRequiredWithoutFavouriteUsersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavouriteUsersInput>;
  create?: InputMaybe<UserCreateWithoutFavouriteUsersInput>;
  update?: InputMaybe<UserUpdateWithoutFavouriteUsersInput>;
  upsert?: InputMaybe<UserUpsertWithoutFavouriteUsersInput>;
};

export type UserUpdateOneRequiredWithoutFavouritedByInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavouritedByInput>;
  create?: InputMaybe<UserCreateWithoutFavouritedByInput>;
  update?: InputMaybe<UserUpdateWithoutFavouritedByInput>;
  upsert?: InputMaybe<UserUpsertWithoutFavouritedByInput>;
};

export type UserUpdateOneRequiredWithoutFriendUserFriendsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFriendUserFriendsInput>;
  create?: InputMaybe<UserCreateWithoutFriendUserFriendsInput>;
  update?: InputMaybe<UserUpdateWithoutFriendUserFriendsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFriendUserFriendsInput>;
};

export type UserUpdateOneRequiredWithoutPaymentLogsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<UserCreateWithoutPaymentLogsInput>;
  update?: InputMaybe<UserUpdateWithoutPaymentLogsInput>;
  upsert?: InputMaybe<UserUpsertWithoutPaymentLogsInput>;
};

export type UserUpdateOneRequiredWithoutPaymentMethodCreditCardsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPaymentMethodCreditCardsInput>;
  create?: InputMaybe<UserCreateWithoutPaymentMethodCreditCardsInput>;
  update?: InputMaybe<UserUpdateWithoutPaymentMethodCreditCardsInput>;
  upsert?: InputMaybe<UserUpsertWithoutPaymentMethodCreditCardsInput>;
};

export type UserUpdateOneRequiredWithoutReporterInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReporterInput>;
  create?: InputMaybe<UserCreateWithoutReporterInput>;
  update?: InputMaybe<UserUpdateWithoutReporterInput>;
  upsert?: InputMaybe<UserUpsertWithoutReporterInput>;
};

export type UserUpdateOneRequiredWithoutTicketsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTicketsInput>;
  create?: InputMaybe<UserCreateWithoutTicketsInput>;
  update?: InputMaybe<UserUpdateWithoutTicketsInput>;
  upsert?: InputMaybe<UserUpsertWithoutTicketsInput>;
};

export type UserUpdateOneRequiredWithoutUserBlacklistsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserBlacklistsInput>;
  create?: InputMaybe<UserCreateWithoutUserBlacklistsInput>;
  update?: InputMaybe<UserUpdateWithoutUserBlacklistsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserBlacklistsInput>;
};

export type UserUpdateOneRequiredWithoutUserFriendsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserFriendsInput>;
  create?: InputMaybe<UserCreateWithoutUserFriendsInput>;
  update?: InputMaybe<UserUpdateWithoutUserFriendsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserFriendsInput>;
};

export type UserUpdateOneRequiredWithoutViewedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutViewedInput>;
  create?: InputMaybe<UserCreateWithoutViewedInput>;
  update?: InputMaybe<UserUpdateWithoutViewedInput>;
  upsert?: InputMaybe<UserUpsertWithoutViewedInput>;
};

export type UserUpdateOneRequiredWithoutViewersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutViewersInput>;
  create?: InputMaybe<UserCreateWithoutViewersInput>;
  update?: InputMaybe<UserUpdateWithoutViewersInput>;
  upsert?: InputMaybe<UserUpsertWithoutViewersInput>;
};

export type UserUpdateOneWithoutCreditLogsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreditLogsInput>;
  create?: InputMaybe<UserCreateWithoutCreditLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutCreditLogsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreditLogsInput>;
};

export type UserUpdateOneWithoutReceivedMessagesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceivedMessagesInput>;
  create?: InputMaybe<UserCreateWithoutReceivedMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutReceivedMessagesInput>;
  upsert?: InputMaybe<UserUpsertWithoutReceivedMessagesInput>;
};

export type UserUpdateOneWithoutReporteeInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReporteeInput>;
  create?: InputMaybe<UserCreateWithoutReporteeInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutReporteeInput>;
  upsert?: InputMaybe<UserUpsertWithoutReporteeInput>;
};

export type UserUpdateOneWithoutSentMessagesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentMessagesInput>;
  create?: InputMaybe<UserCreateWithoutSentMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutSentMessagesInput>;
  upsert?: InputMaybe<UserUpsertWithoutSentMessagesInput>;
};

export type UserUpdateOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<UserCreateWithoutSubscriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutSubscriptionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSubscriptionsInput>;
};

export type UserUpdateWithoutAlbumImagesInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutBlacklistedUserblacklistsInput = {
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutCreditLogsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutFavouriteUsersInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutFavouritedByInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutFriendUserFriendsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutPaymentLogsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutPaymentMethodCreditCardsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutReceivedMessagesInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutReporteeInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutReporterInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutSentMessagesInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutSubscriptionsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutTicketsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutUserBlacklistsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutUserFriendsInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutViewedInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewers?: InputMaybe<UserViewLogUpdateManyWithoutTargetUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpdateWithoutViewersInput = {
  BlacklistedUserblacklists?: InputMaybe<UserBlacklistUpdateManyWithoutBlacklistedUserInput>;
  aboutBody?: InputMaybe<Scalars['Int']>;
  aboutDrink?: InputMaybe<Scalars['Int']>;
  aboutEconomy?: InputMaybe<Scalars['Int']>;
  aboutHeight?: InputMaybe<Scalars['Int']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutSmoke?: InputMaybe<Scalars['Int']>;
  aboutStyle?: InputMaybe<Scalars['Int']>;
  aboutWaist?: InputMaybe<Scalars['Int']>;
  adminRemark?: InputMaybe<Scalars['String']>;
  adminRemarkImages?: InputMaybe<UserAdminRemarkImageUpdateManyWithoutUserInput>;
  adminRemarkUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  albumImages?: InputMaybe<AlbumImageUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cloneGroupId?: InputMaybe<Scalars['String']>;
  contactPrivacy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutUserInput>;
  creditSpent?: InputMaybe<Scalars['Int']>;
  dateExpense?: InputMaybe<Scalars['Int']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  delete_confirm_code?: InputMaybe<Scalars['String']>;
  delete_dt?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  educationLevel?: InputMaybe<EducationLevelEnum>;
  email?: InputMaybe<Scalars['String']>;
  emptyWine?: InputMaybe<Scalars['Int']>;
  facebook_id?: InputMaybe<Scalars['String']>;
  favouriteUsers?: InputMaybe<UserFavouriteUpdateManyWithoutUserInput>;
  favouritedBy?: InputMaybe<UserFavouriteUpdateManyWithoutFavouriteUserInput>;
  fname?: InputMaybe<Scalars['String']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  friendUserFriends?: InputMaybe<UserFriendUpdateManyWithoutFriendUserInput>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<Scalars['Int']>;
  hide_end_dt?: InputMaybe<Scalars['DateTime']>;
  highlight_end_dt?: InputMaybe<Scalars['DateTime']>;
  lang?: InputMaybe<Scalars['String']>;
  lastIpv4?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['DateTime']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  millionbb?: InputMaybe<Scalars['Boolean']>;
  minFrdWine?: InputMaybe<Scalars['Int']>;
  msgQuota?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutUserInput>;
  paymentMethodCreditCards?: InputMaybe<PaymentCreditCardUpdateManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  premiumEndDt?: InputMaybe<Scalars['DateTime']>;
  princessDating?: InputMaybe<Scalars['Boolean']>;
  privateAlbumDescription?: InputMaybe<Scalars['String']>;
  privateAlbumTitle?: InputMaybe<Scalars['String']>;
  publicAlbumDescription?: InputMaybe<Scalars['String']>;
  publicAlbumTitle?: InputMaybe<Scalars['String']>;
  rateLevel?: InputMaybe<Scalars['Int']>;
  receivedMessages?: InputMaybe<UserMessageUpdateManyWithoutRecipantUserInput>;
  refId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['Int']>;
  reg_ipv4?: InputMaybe<Scalars['String']>;
  reportee?: InputMaybe<ReportUpdateManyWithoutUserInput>;
  reporter?: InputMaybe<ReportUpdateManyWithoutReporterInput>;
  restrictedLevel?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['Int']>;
  searchTarget?: InputMaybe<SearchTargetEnum>;
  sentMessages?: InputMaybe<UserMessageUpdateManyWithoutSenderUserInput>;
  show_adult?: InputMaybe<Scalars['Int']>;
  silverEndDt?: InputMaybe<Scalars['DateTime']>;
  sname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutUserInput>;
  telegram?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketUpdateManyWithoutUserInput>;
  tncAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBlacklists?: InputMaybe<UserBlacklistUpdateManyWithoutUserInput>;
  userFriends?: InputMaybe<UserFriendUpdateManyWithoutUserInput>;
  verifyCode?: InputMaybe<Scalars['String']>;
  viewed?: InputMaybe<UserViewLogUpdateManyWithoutUserInput>;
  vip?: InputMaybe<Scalars['Int']>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  wine?: InputMaybe<Scalars['Int']>;
};

export type UserUpsertWithoutAlbumImagesInput = {
  create: UserCreateWithoutAlbumImagesInput;
  update: UserUpdateWithoutAlbumImagesInput;
};

export type UserUpsertWithoutBlacklistedUserblacklistsInput = {
  create: UserCreateWithoutBlacklistedUserblacklistsInput;
  update: UserUpdateWithoutBlacklistedUserblacklistsInput;
};

export type UserUpsertWithoutCreditLogsInput = {
  create: UserCreateWithoutCreditLogsInput;
  update: UserUpdateWithoutCreditLogsInput;
};

export type UserUpsertWithoutFavouriteUsersInput = {
  create: UserCreateWithoutFavouriteUsersInput;
  update: UserUpdateWithoutFavouriteUsersInput;
};

export type UserUpsertWithoutFavouritedByInput = {
  create: UserCreateWithoutFavouritedByInput;
  update: UserUpdateWithoutFavouritedByInput;
};

export type UserUpsertWithoutFriendUserFriendsInput = {
  create: UserCreateWithoutFriendUserFriendsInput;
  update: UserUpdateWithoutFriendUserFriendsInput;
};

export type UserUpsertWithoutPaymentLogsInput = {
  create: UserCreateWithoutPaymentLogsInput;
  update: UserUpdateWithoutPaymentLogsInput;
};

export type UserUpsertWithoutPaymentMethodCreditCardsInput = {
  create: UserCreateWithoutPaymentMethodCreditCardsInput;
  update: UserUpdateWithoutPaymentMethodCreditCardsInput;
};

export type UserUpsertWithoutReceivedMessagesInput = {
  create: UserCreateWithoutReceivedMessagesInput;
  update: UserUpdateWithoutReceivedMessagesInput;
};

export type UserUpsertWithoutReporteeInput = {
  create: UserCreateWithoutReporteeInput;
  update: UserUpdateWithoutReporteeInput;
};

export type UserUpsertWithoutReporterInput = {
  create: UserCreateWithoutReporterInput;
  update: UserUpdateWithoutReporterInput;
};

export type UserUpsertWithoutSentMessagesInput = {
  create: UserCreateWithoutSentMessagesInput;
  update: UserUpdateWithoutSentMessagesInput;
};

export type UserUpsertWithoutSubscriptionsInput = {
  create: UserCreateWithoutSubscriptionsInput;
  update: UserUpdateWithoutSubscriptionsInput;
};

export type UserUpsertWithoutTicketsInput = {
  create: UserCreateWithoutTicketsInput;
  update: UserUpdateWithoutTicketsInput;
};

export type UserUpsertWithoutUserBlacklistsInput = {
  create: UserCreateWithoutUserBlacklistsInput;
  update: UserUpdateWithoutUserBlacklistsInput;
};

export type UserUpsertWithoutUserFriendsInput = {
  create: UserCreateWithoutUserFriendsInput;
  update: UserUpdateWithoutUserFriendsInput;
};

export type UserUpsertWithoutViewedInput = {
  create: UserCreateWithoutViewedInput;
  update: UserUpdateWithoutViewedInput;
};

export type UserUpsertWithoutViewersInput = {
  create: UserCreateWithoutViewersInput;
  update: UserUpdateWithoutViewersInput;
};

export enum UserVerifyEnum {
  Accepted = 'ACCEPTED',
  Empty = 'EMPTY',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  RemovedByAdmin = 'REMOVED_BY_ADMIN',
  RemovedByUser = 'REMOVED_BY_USER'
}

export type UserViewLogCreateManyTargetUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export type UserViewLogCreateManyTargetUserInputEnvelope = {
  data?: InputMaybe<Array<UserViewLogCreateManyTargetUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserViewLogCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  targetUserId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserViewLogCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserViewLogCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserViewLogCreateNestedManyWithoutTargetUserInput = {
  connect?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewLogCreateOrConnectWithoutTargetUserInput>>;
  create?: InputMaybe<Array<UserViewLogCreateWithoutTargetUserInput>>;
  createMany?: InputMaybe<UserViewLogCreateManyTargetUserInputEnvelope>;
};

export type UserViewLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserViewLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserViewLogCreateManyUserInputEnvelope>;
};

export type UserViewLogCreateOrConnectWithoutTargetUserInput = {
  create: UserViewLogCreateWithoutTargetUserInput;
  where: UserViewLogWhereUniqueInput;
};

export type UserViewLogCreateOrConnectWithoutUserInput = {
  create: UserViewLogCreateWithoutUserInput;
  where: UserViewLogWhereUniqueInput;
};

export type UserViewLogCreateWithoutTargetUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutViewedInput;
};

export type UserViewLogCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  targetUser: UserCreateNestedOneWithoutViewersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserViewLogScalarWhereInput = {
  AND?: InputMaybe<Array<UserViewLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserViewLogScalarWhereInput>>;
  OR?: InputMaybe<Array<UserViewLogScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  targetUserId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserViewLogUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserViewLogUpdateManyWithWhereWithoutTargetUserInput = {
  data: UserViewLogUpdateManyMutationInput;
  where: UserViewLogScalarWhereInput;
};

export type UserViewLogUpdateManyWithWhereWithoutUserInput = {
  data: UserViewLogUpdateManyMutationInput;
  where: UserViewLogScalarWhereInput;
};

export type UserViewLogUpdateManyWithoutTargetUserInput = {
  connect?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewLogCreateOrConnectWithoutTargetUserInput>>;
  create?: InputMaybe<Array<UserViewLogCreateWithoutTargetUserInput>>;
  createMany?: InputMaybe<UserViewLogCreateManyTargetUserInputEnvelope>;
  delete?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserViewLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  set?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  update?: InputMaybe<Array<UserViewLogUpdateWithWhereUniqueWithoutTargetUserInput>>;
  updateMany?: InputMaybe<Array<UserViewLogUpdateManyWithWhereWithoutTargetUserInput>>;
  upsert?: InputMaybe<Array<UserViewLogUpsertWithWhereUniqueWithoutTargetUserInput>>;
};

export type UserViewLogUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserViewLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserViewLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserViewLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  set?: InputMaybe<Array<UserViewLogWhereUniqueInput>>;
  update?: InputMaybe<Array<UserViewLogUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserViewLogUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserViewLogUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserViewLogUpdateWithWhereUniqueWithoutTargetUserInput = {
  data: UserViewLogUpdateWithoutTargetUserInput;
  where: UserViewLogWhereUniqueInput;
};

export type UserViewLogUpdateWithWhereUniqueWithoutUserInput = {
  data: UserViewLogUpdateWithoutUserInput;
  where: UserViewLogWhereUniqueInput;
};

export type UserViewLogUpdateWithoutTargetUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutViewedInput>;
};

export type UserViewLogUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  targetUser?: InputMaybe<UserUpdateOneRequiredWithoutViewersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserViewLogUpsertWithWhereUniqueWithoutTargetUserInput = {
  create: UserViewLogCreateWithoutTargetUserInput;
  update: UserViewLogUpdateWithoutTargetUserInput;
  where: UserViewLogWhereUniqueInput;
};

export type UserViewLogUpsertWithWhereUniqueWithoutUserInput = {
  create: UserViewLogCreateWithoutUserInput;
  update: UserViewLogUpdateWithoutUserInput;
  where: UserViewLogWhereUniqueInput;
};

export type UserViewLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<UserWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<UserWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<UserWhereInput>>>;
  ageVerify?: InputMaybe<IntFilter>;
  dob?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  nickname?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  rateLevel?: InputMaybe<IntFilter>;
  role?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  voice?: InputMaybe<IntFilter>;
  wine?: InputMaybe<IntFilter>;
};

export type UserWhereUniqueInput = {
  id: Scalars['Int'];
};

export type ViewsWhereInput = {
  targetUserId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum WineReturnStatusEnum {
  Accepted = 'ACCEPTED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type WineToCreditPayload = {
  __typename?: 'WineToCreditPayload';
  success: Scalars['Boolean'];
};

export type CommentFragment = { __typename?: 'PrincessComment', id: number, userId: number, targetUserId: number, content?: string | null, rate?: number | null, anonymousDate?: any | null, createdAt: any, updatedAt: any, user?: { __typename?: 'PrincessUser', avatar?: string | null, avatarBanned: boolean, nickname?: string | null, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum } | null, targetUser?: { __typename?: 'PrincessUser', avatar?: string | null, avatarBanned: boolean, nickname?: string | null, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum } | null };

export type PaymentLogFragment = { __typename?: 'PrincessPaymentLog', id: number, userId: number, paymentType: PaymentTypeEnum, amount: number, subscriptionId?: number | null, logType: LogTypeEnum, refTxnId?: string | null, imageUrl?: string | null, createdAt: any, updatedAt: any, product?: { __typename?: 'PrincessProduct', type: ProductType, rewardQuantity: number } | null, subscription?: { __typename?: 'PrincessSubscription', subscriptionPlan?: { __typename?: 'PrincessSubscriptionPlan', role: UserRoleEnum, amount: number, activeDays: number, isPrincess: boolean } | null } | null };

export type ProductOverviewFragment = { __typename?: 'PrincessProduct', type: ProductType, rewardQuantity: number };

export type SearchUserFragment = { __typename?: 'SearchUser', id: number, gender: GenderEnum, nickname?: string | null, dob?: any | null, city?: string | null, avatar?: string | null, avatarBanned: boolean, ageVerify: UserVerifyEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, contactPrivacy?: UserContactPrivacyEnum | null, lastSeen: any, voice: UserVerifyEnum, createdAt: any, updatedAt: any, minFrdWine?: number | null };

export type SubscriptionPlanOverviewFragment = { __typename?: 'PrincessSubscriptionPlan', role: UserRoleEnum, amount: number, activeDays: number, isPrincess: boolean };

export type UserFragment = { __typename?: 'PrincessUser', id: number, nickname?: string | null, logo?: string | null, avatar?: string | null, avatarBanned: boolean, city?: string | null, dob?: any | null, gender: GenderEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, voice: UserVerifyEnum, ageVerify: UserVerifyEnum };

export type ActivateSubscriptionRebillMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateSubscriptionRebillMutation = { __typename?: 'Mutation', activateSubscriptionRebill?: { __typename?: 'PrincessSubscription', id: number } | null };

export type ReportUserMutationVariables = Exact<{
  data: ReportUserInputArgs;
}>;


export type ReportUserMutation = { __typename?: 'Mutation', reportUser?: number | null };

export type UpdateUserAvatarMutationVariables = Exact<{
  avatar?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserAvatarMutation = { __typename?: 'Mutation', updateUserAvatar?: { __typename?: 'User', id: number } | null };

export type UserAcceptTermsMutationVariables = Exact<{ [key: string]: never; }>;


export type UserAcceptTermsMutation = { __typename?: 'Mutation', userAcceptTerms?: { __typename?: 'User', id: number } | null };

export type UserOnboardMutationVariables = Exact<{
  data: UserOnboardData;
}>;


export type UserOnboardMutation = { __typename?: 'Mutation', userOnboard: { __typename?: 'UserOnboardPayload', idToken: string } };

export type GetBlacklistReocrdsQueryVariables = Exact<{
  includeBlacklisted?: InputMaybe<Scalars['Boolean']>;
  includeBlacklistedBy?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetBlacklistReocrdsQuery = { __typename?: 'Query', getBlacklistReocrds?: { __typename?: 'GetBlacklistReocrdsPayload', blacklistedUserIds?: Array<number> | null, blacklistedByUserIds?: Array<number> | null } | null };

export type CollectMonthlyNfmqMutationVariables = Exact<{ [key: string]: never; }>;


export type CollectMonthlyNfmqMutation = { __typename?: 'Mutation', collectMonthlyNfmq?: boolean | null };

export type CommentCountQueryVariables = Exact<{
  where: CommentsWhereInput;
}>;


export type CommentCountQuery = { __typename?: 'Query', commentCount?: number | null };

export type CommentsQueryVariables = Exact<{
  where: CommentsWhereInput;
  take?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['Int']>;
}>;


export type CommentsQuery = { __typename?: 'Query', comments: Array<{ __typename?: 'PrincessComment', id: number, userId: number, targetUserId: number, content?: string | null, rate?: number | null, anonymousDate?: any | null, createdAt: any, updatedAt: any, user?: { __typename?: 'PrincessUser', avatar?: string | null, avatarBanned: boolean, nickname?: string | null, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum } | null, targetUser?: { __typename?: 'PrincessUser', avatar?: string | null, avatarBanned: boolean, nickname?: string | null, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum } | null }> };

export type ConvertedWineCreditLogQueryVariables = Exact<{ [key: string]: never; }>;


export type ConvertedWineCreditLogQuery = { __typename?: 'Query', convertedWineCreditLog?: { __typename?: 'CreditLog', amount: number } | null };

export type FriendCountQueryVariables = Exact<{ [key: string]: never; }>;


export type FriendCountQuery = { __typename?: 'Query', friendCount: number };

export type FriendsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type FriendsQuery = { __typename?: 'Query', friends: Array<{ __typename?: 'PrincessUser', id: number, nickname?: string | null, logo?: string | null, avatar?: string | null, avatarBanned: boolean, city?: string | null, dob?: any | null, gender: GenderEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, voice: UserVerifyEnum, ageVerify: UserVerifyEnum }> };

export type HasIssuedNfmqThisMonthQueryVariables = Exact<{ [key: string]: never; }>;


export type HasIssuedNfmqThisMonthQuery = { __typename?: 'Query', hasIssuedNfmqThisMonth?: { __typename?: 'GetHasIssuedNfmqThisMonthPayload', issued: boolean } | null };

export type PaymentLogCountQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentLogCountQuery = { __typename?: 'Query', paymentLogCount: number };

export type PaymentLogsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PaymentLogsQuery = { __typename?: 'Query', paymentLogs: Array<{ __typename?: 'PrincessPaymentLog', id: number, userId: number, paymentType: PaymentTypeEnum, amount: number, subscriptionId?: number | null, logType: LogTypeEnum, refTxnId?: string | null, imageUrl?: string | null, createdAt: any, updatedAt: any, product?: { __typename?: 'PrincessProduct', type: ProductType, rewardQuantity: number } | null, subscription?: { __typename?: 'PrincessSubscription', subscriptionPlan?: { __typename?: 'PrincessSubscriptionPlan', role: UserRoleEnum, amount: number, activeDays: number, isPrincess: boolean } | null } | null }> };

export type SearchUsersQueryVariables = Exact<{
  where: SearchUsersWhereInput;
  take: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: { __typename?: 'SearchUsersPayload', count: number, users: Array<{ __typename?: 'SearchUser', id: number, gender: GenderEnum, nickname?: string | null, dob?: any | null, city?: string | null, avatar?: string | null, avatarBanned: boolean, ageVerify: UserVerifyEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, contactPrivacy?: UserContactPrivacyEnum | null, lastSeen: any, voice: UserVerifyEnum, createdAt: any, updatedAt: any, minFrdWine?: number | null }> } };

export type ViewCountQueryVariables = Exact<{
  where: ViewsWhereInput;
}>;


export type ViewCountQuery = { __typename?: 'Query', viewCount: number };

export type ViewsQueryVariables = Exact<{
  where: ViewsWhereInput;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type ViewsQuery = { __typename?: 'Query', views: Array<{ __typename?: 'PrincessUser', id: number, nickname?: string | null, logo?: string | null, avatar?: string | null, avatarBanned: boolean, city?: string | null, dob?: any | null, gender: GenderEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, voice: UserVerifyEnum, ageVerify: UserVerifyEnum }> };

export const CommentFragmentDoc = `
    fragment Comment on PrincessComment {
  id
  userId
  targetUserId
  content
  rate
  anonymousDate
  createdAt
  updatedAt
  user {
    avatar
    avatarBanned
    nickname
    role
    status
    rateLevel
  }
  targetUser {
    avatar
    avatarBanned
    nickname
    role
    status
    rateLevel
  }
}
    `;
export const ProductOverviewFragmentDoc = `
    fragment ProductOverview on PrincessProduct {
  type
  rewardQuantity
}
    `;
export const SubscriptionPlanOverviewFragmentDoc = `
    fragment SubscriptionPlanOverview on PrincessSubscriptionPlan {
  role
  amount
  activeDays
  isPrincess
}
    `;
export const PaymentLogFragmentDoc = `
    fragment PaymentLog on PrincessPaymentLog {
  id
  userId
  paymentType
  amount
  subscriptionId
  logType
  refTxnId
  imageUrl
  product {
    ...ProductOverview
  }
  subscription {
    subscriptionPlan {
      ...SubscriptionPlanOverview
    }
  }
  createdAt
  updatedAt
}
    ${ProductOverviewFragmentDoc}
${SubscriptionPlanOverviewFragmentDoc}`;
export const SearchUserFragmentDoc = `
    fragment SearchUser on SearchUser {
  id
  gender
  nickname
  dob
  city
  avatar
  avatarBanned
  ageVerify
  role
  status
  rateLevel
  contactPrivacy
  lastSeen
  voice
  createdAt
  updatedAt
  minFrdWine
}
    `;
export const UserFragmentDoc = `
    fragment User on PrincessUser {
  id
  nickname
  logo
  avatar
  avatarBanned
  city
  dob
  gender
  role
  status
  rateLevel
  voice
  ageVerify
}
    `;
export const ActivateSubscriptionRebillDocument = `
    mutation activateSubscriptionRebill {
  activateSubscriptionRebill {
    id
  }
}
    `;
export const useActivateSubscriptionRebillMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ActivateSubscriptionRebillMutation, TError, ActivateSubscriptionRebillMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ActivateSubscriptionRebillMutation, TError, ActivateSubscriptionRebillMutationVariables, TContext>(
      ['activateSubscriptionRebill'],
      (variables?: ActivateSubscriptionRebillMutationVariables) => fetcher<ActivateSubscriptionRebillMutation, ActivateSubscriptionRebillMutationVariables>(client, ActivateSubscriptionRebillDocument, variables, headers)(),
      options
    );
export const ReportUserDocument = `
    mutation reportUser($data: ReportUserInputArgs!) {
  reportUser(data: $data)
}
    `;
export const useReportUserMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ReportUserMutation, TError, ReportUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ReportUserMutation, TError, ReportUserMutationVariables, TContext>(
      ['reportUser'],
      (variables?: ReportUserMutationVariables) => fetcher<ReportUserMutation, ReportUserMutationVariables>(client, ReportUserDocument, variables, headers)(),
      options
    );
export const UpdateUserAvatarDocument = `
    mutation updateUserAvatar($avatar: String) {
  updateUserAvatar(avatar: $avatar) {
    id
  }
}
    `;
export const useUpdateUserAvatarMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserAvatarMutation, TError, UpdateUserAvatarMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserAvatarMutation, TError, UpdateUserAvatarMutationVariables, TContext>(
      ['updateUserAvatar'],
      (variables?: UpdateUserAvatarMutationVariables) => fetcher<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>(client, UpdateUserAvatarDocument, variables, headers)(),
      options
    );
export const UserAcceptTermsDocument = `
    mutation userAcceptTerms {
  userAcceptTerms {
    id
  }
}
    `;
export const useUserAcceptTermsMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserAcceptTermsMutation, TError, UserAcceptTermsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserAcceptTermsMutation, TError, UserAcceptTermsMutationVariables, TContext>(
      ['userAcceptTerms'],
      (variables?: UserAcceptTermsMutationVariables) => fetcher<UserAcceptTermsMutation, UserAcceptTermsMutationVariables>(client, UserAcceptTermsDocument, variables, headers)(),
      options
    );
export const UserOnboardDocument = `
    mutation userOnboard($data: UserOnboardData!) {
  userOnboard(data: $data) {
    idToken
  }
}
    `;
export const useUserOnboardMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserOnboardMutation, TError, UserOnboardMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserOnboardMutation, TError, UserOnboardMutationVariables, TContext>(
      ['userOnboard'],
      (variables?: UserOnboardMutationVariables) => fetcher<UserOnboardMutation, UserOnboardMutationVariables>(client, UserOnboardDocument, variables, headers)(),
      options
    );
export const GetBlacklistReocrdsDocument = `
    query getBlacklistReocrds($includeBlacklisted: Boolean = false, $includeBlacklistedBy: Boolean = false) {
  getBlacklistReocrds {
    blacklistedUserIds @include(if: $includeBlacklisted)
    blacklistedByUserIds @include(if: $includeBlacklistedBy)
  }
}
    `;
export const useGetBlacklistReocrdsQuery = <
      TData = GetBlacklistReocrdsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: GetBlacklistReocrdsQueryVariables,
      options?: UseQueryOptions<GetBlacklistReocrdsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetBlacklistReocrdsQuery, TError, TData>(
      variables === undefined ? ['getBlacklistReocrds'] : ['getBlacklistReocrds', variables],
      fetcher<GetBlacklistReocrdsQuery, GetBlacklistReocrdsQueryVariables>(client, GetBlacklistReocrdsDocument, variables, headers),
      options
    );

useGetBlacklistReocrdsQuery.getKey = (variables?: GetBlacklistReocrdsQueryVariables) => variables === undefined ? ['getBlacklistReocrds'] : ['getBlacklistReocrds', variables];
;

export const useInfiniteGetBlacklistReocrdsQuery = <
      TData = GetBlacklistReocrdsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof GetBlacklistReocrdsQueryVariables,
      client: GraphQLClient,
      variables?: GetBlacklistReocrdsQueryVariables,
      options?: UseInfiniteQueryOptions<GetBlacklistReocrdsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetBlacklistReocrdsQuery, TError, TData>(
      variables === undefined ? ['getBlacklistReocrds.infinite'] : ['getBlacklistReocrds.infinite', variables],
      (metaData) => fetcher<GetBlacklistReocrdsQuery, GetBlacklistReocrdsQueryVariables>(client, GetBlacklistReocrdsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetBlacklistReocrdsQuery.getKey = (variables?: GetBlacklistReocrdsQueryVariables) => variables === undefined ? ['getBlacklistReocrds.infinite'] : ['getBlacklistReocrds.infinite', variables];
;

export const CollectMonthlyNfmqDocument = `
    mutation collectMonthlyNfmq {
  collectMonthlyNfmq
}
    `;
export const useCollectMonthlyNfmqMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CollectMonthlyNfmqMutation, TError, CollectMonthlyNfmqMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CollectMonthlyNfmqMutation, TError, CollectMonthlyNfmqMutationVariables, TContext>(
      ['collectMonthlyNfmq'],
      (variables?: CollectMonthlyNfmqMutationVariables) => fetcher<CollectMonthlyNfmqMutation, CollectMonthlyNfmqMutationVariables>(client, CollectMonthlyNfmqDocument, variables, headers)(),
      options
    );
export const CommentCountDocument = `
    query commentCount($where: CommentsWhereInput!) {
  commentCount(where: $where)
}
    `;
export const useCommentCountQuery = <
      TData = CommentCountQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: CommentCountQueryVariables,
      options?: UseQueryOptions<CommentCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CommentCountQuery, TError, TData>(
      ['commentCount', variables],
      fetcher<CommentCountQuery, CommentCountQueryVariables>(client, CommentCountDocument, variables, headers),
      options
    );

useCommentCountQuery.getKey = (variables: CommentCountQueryVariables) => ['commentCount', variables];
;

export const useInfiniteCommentCountQuery = <
      TData = CommentCountQuery,
      TError = ClientError
    >(
      pageParamKey: keyof CommentCountQueryVariables,
      client: GraphQLClient,
      variables: CommentCountQueryVariables,
      options?: UseInfiniteQueryOptions<CommentCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<CommentCountQuery, TError, TData>(
      ['commentCount.infinite', variables],
      (metaData) => fetcher<CommentCountQuery, CommentCountQueryVariables>(client, CommentCountDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteCommentCountQuery.getKey = (variables: CommentCountQueryVariables) => ['commentCount.infinite', variables];
;

export const CommentsDocument = `
    query comments($where: CommentsWhereInput!, $take: Int, $cursor: Int) {
  comments(where: $where, take: $take, cursor: $cursor) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export const useCommentsQuery = <
      TData = CommentsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: CommentsQueryVariables,
      options?: UseQueryOptions<CommentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CommentsQuery, TError, TData>(
      ['comments', variables],
      fetcher<CommentsQuery, CommentsQueryVariables>(client, CommentsDocument, variables, headers),
      options
    );

useCommentsQuery.getKey = (variables: CommentsQueryVariables) => ['comments', variables];
;

export const useInfiniteCommentsQuery = <
      TData = CommentsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof CommentsQueryVariables,
      client: GraphQLClient,
      variables: CommentsQueryVariables,
      options?: UseInfiniteQueryOptions<CommentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<CommentsQuery, TError, TData>(
      ['comments.infinite', variables],
      (metaData) => fetcher<CommentsQuery, CommentsQueryVariables>(client, CommentsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteCommentsQuery.getKey = (variables: CommentsQueryVariables) => ['comments.infinite', variables];
;

export const ConvertedWineCreditLogDocument = `
    query convertedWineCreditLog {
  convertedWineCreditLog {
    amount
  }
}
    `;
export const useConvertedWineCreditLogQuery = <
      TData = ConvertedWineCreditLogQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: ConvertedWineCreditLogQueryVariables,
      options?: UseQueryOptions<ConvertedWineCreditLogQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ConvertedWineCreditLogQuery, TError, TData>(
      variables === undefined ? ['convertedWineCreditLog'] : ['convertedWineCreditLog', variables],
      fetcher<ConvertedWineCreditLogQuery, ConvertedWineCreditLogQueryVariables>(client, ConvertedWineCreditLogDocument, variables, headers),
      options
    );

useConvertedWineCreditLogQuery.getKey = (variables?: ConvertedWineCreditLogQueryVariables) => variables === undefined ? ['convertedWineCreditLog'] : ['convertedWineCreditLog', variables];
;

export const useInfiniteConvertedWineCreditLogQuery = <
      TData = ConvertedWineCreditLogQuery,
      TError = ClientError
    >(
      pageParamKey: keyof ConvertedWineCreditLogQueryVariables,
      client: GraphQLClient,
      variables?: ConvertedWineCreditLogQueryVariables,
      options?: UseInfiniteQueryOptions<ConvertedWineCreditLogQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<ConvertedWineCreditLogQuery, TError, TData>(
      variables === undefined ? ['convertedWineCreditLog.infinite'] : ['convertedWineCreditLog.infinite', variables],
      (metaData) => fetcher<ConvertedWineCreditLogQuery, ConvertedWineCreditLogQueryVariables>(client, ConvertedWineCreditLogDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteConvertedWineCreditLogQuery.getKey = (variables?: ConvertedWineCreditLogQueryVariables) => variables === undefined ? ['convertedWineCreditLog.infinite'] : ['convertedWineCreditLog.infinite', variables];
;

export const FriendCountDocument = `
    query friendCount {
  friendCount
}
    `;
export const useFriendCountQuery = <
      TData = FriendCountQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: FriendCountQueryVariables,
      options?: UseQueryOptions<FriendCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<FriendCountQuery, TError, TData>(
      variables === undefined ? ['friendCount'] : ['friendCount', variables],
      fetcher<FriendCountQuery, FriendCountQueryVariables>(client, FriendCountDocument, variables, headers),
      options
    );

useFriendCountQuery.getKey = (variables?: FriendCountQueryVariables) => variables === undefined ? ['friendCount'] : ['friendCount', variables];
;

export const useInfiniteFriendCountQuery = <
      TData = FriendCountQuery,
      TError = ClientError
    >(
      pageParamKey: keyof FriendCountQueryVariables,
      client: GraphQLClient,
      variables?: FriendCountQueryVariables,
      options?: UseInfiniteQueryOptions<FriendCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<FriendCountQuery, TError, TData>(
      variables === undefined ? ['friendCount.infinite'] : ['friendCount.infinite', variables],
      (metaData) => fetcher<FriendCountQuery, FriendCountQueryVariables>(client, FriendCountDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteFriendCountQuery.getKey = (variables?: FriendCountQueryVariables) => variables === undefined ? ['friendCount.infinite'] : ['friendCount.infinite', variables];
;

export const FriendsDocument = `
    query friends($skip: Int, $take: Int) {
  friends(skip: $skip, take: $take) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const useFriendsQuery = <
      TData = FriendsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: FriendsQueryVariables,
      options?: UseQueryOptions<FriendsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<FriendsQuery, TError, TData>(
      variables === undefined ? ['friends'] : ['friends', variables],
      fetcher<FriendsQuery, FriendsQueryVariables>(client, FriendsDocument, variables, headers),
      options
    );

useFriendsQuery.getKey = (variables?: FriendsQueryVariables) => variables === undefined ? ['friends'] : ['friends', variables];
;

export const useInfiniteFriendsQuery = <
      TData = FriendsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof FriendsQueryVariables,
      client: GraphQLClient,
      variables?: FriendsQueryVariables,
      options?: UseInfiniteQueryOptions<FriendsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<FriendsQuery, TError, TData>(
      variables === undefined ? ['friends.infinite'] : ['friends.infinite', variables],
      (metaData) => fetcher<FriendsQuery, FriendsQueryVariables>(client, FriendsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteFriendsQuery.getKey = (variables?: FriendsQueryVariables) => variables === undefined ? ['friends.infinite'] : ['friends.infinite', variables];
;

export const HasIssuedNfmqThisMonthDocument = `
    query hasIssuedNfmqThisMonth {
  hasIssuedNfmqThisMonth {
    issued
  }
}
    `;
export const useHasIssuedNfmqThisMonthQuery = <
      TData = HasIssuedNfmqThisMonthQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: HasIssuedNfmqThisMonthQueryVariables,
      options?: UseQueryOptions<HasIssuedNfmqThisMonthQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<HasIssuedNfmqThisMonthQuery, TError, TData>(
      variables === undefined ? ['hasIssuedNfmqThisMonth'] : ['hasIssuedNfmqThisMonth', variables],
      fetcher<HasIssuedNfmqThisMonthQuery, HasIssuedNfmqThisMonthQueryVariables>(client, HasIssuedNfmqThisMonthDocument, variables, headers),
      options
    );

useHasIssuedNfmqThisMonthQuery.getKey = (variables?: HasIssuedNfmqThisMonthQueryVariables) => variables === undefined ? ['hasIssuedNfmqThisMonth'] : ['hasIssuedNfmqThisMonth', variables];
;

export const useInfiniteHasIssuedNfmqThisMonthQuery = <
      TData = HasIssuedNfmqThisMonthQuery,
      TError = ClientError
    >(
      pageParamKey: keyof HasIssuedNfmqThisMonthQueryVariables,
      client: GraphQLClient,
      variables?: HasIssuedNfmqThisMonthQueryVariables,
      options?: UseInfiniteQueryOptions<HasIssuedNfmqThisMonthQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<HasIssuedNfmqThisMonthQuery, TError, TData>(
      variables === undefined ? ['hasIssuedNfmqThisMonth.infinite'] : ['hasIssuedNfmqThisMonth.infinite', variables],
      (metaData) => fetcher<HasIssuedNfmqThisMonthQuery, HasIssuedNfmqThisMonthQueryVariables>(client, HasIssuedNfmqThisMonthDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteHasIssuedNfmqThisMonthQuery.getKey = (variables?: HasIssuedNfmqThisMonthQueryVariables) => variables === undefined ? ['hasIssuedNfmqThisMonth.infinite'] : ['hasIssuedNfmqThisMonth.infinite', variables];
;

export const PaymentLogCountDocument = `
    query paymentLogCount {
  paymentLogCount
}
    `;
export const usePaymentLogCountQuery = <
      TData = PaymentLogCountQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: PaymentLogCountQueryVariables,
      options?: UseQueryOptions<PaymentLogCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PaymentLogCountQuery, TError, TData>(
      variables === undefined ? ['paymentLogCount'] : ['paymentLogCount', variables],
      fetcher<PaymentLogCountQuery, PaymentLogCountQueryVariables>(client, PaymentLogCountDocument, variables, headers),
      options
    );

usePaymentLogCountQuery.getKey = (variables?: PaymentLogCountQueryVariables) => variables === undefined ? ['paymentLogCount'] : ['paymentLogCount', variables];
;

export const useInfinitePaymentLogCountQuery = <
      TData = PaymentLogCountQuery,
      TError = ClientError
    >(
      pageParamKey: keyof PaymentLogCountQueryVariables,
      client: GraphQLClient,
      variables?: PaymentLogCountQueryVariables,
      options?: UseInfiniteQueryOptions<PaymentLogCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<PaymentLogCountQuery, TError, TData>(
      variables === undefined ? ['paymentLogCount.infinite'] : ['paymentLogCount.infinite', variables],
      (metaData) => fetcher<PaymentLogCountQuery, PaymentLogCountQueryVariables>(client, PaymentLogCountDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfinitePaymentLogCountQuery.getKey = (variables?: PaymentLogCountQueryVariables) => variables === undefined ? ['paymentLogCount.infinite'] : ['paymentLogCount.infinite', variables];
;

export const PaymentLogsDocument = `
    query paymentLogs($take: Int, $skip: Int) {
  paymentLogs(take: $take, skip: $skip) {
    ...PaymentLog
  }
}
    ${PaymentLogFragmentDoc}`;
export const usePaymentLogsQuery = <
      TData = PaymentLogsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: PaymentLogsQueryVariables,
      options?: UseQueryOptions<PaymentLogsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PaymentLogsQuery, TError, TData>(
      variables === undefined ? ['paymentLogs'] : ['paymentLogs', variables],
      fetcher<PaymentLogsQuery, PaymentLogsQueryVariables>(client, PaymentLogsDocument, variables, headers),
      options
    );

usePaymentLogsQuery.getKey = (variables?: PaymentLogsQueryVariables) => variables === undefined ? ['paymentLogs'] : ['paymentLogs', variables];
;

export const useInfinitePaymentLogsQuery = <
      TData = PaymentLogsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof PaymentLogsQueryVariables,
      client: GraphQLClient,
      variables?: PaymentLogsQueryVariables,
      options?: UseInfiniteQueryOptions<PaymentLogsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<PaymentLogsQuery, TError, TData>(
      variables === undefined ? ['paymentLogs.infinite'] : ['paymentLogs.infinite', variables],
      (metaData) => fetcher<PaymentLogsQuery, PaymentLogsQueryVariables>(client, PaymentLogsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfinitePaymentLogsQuery.getKey = (variables?: PaymentLogsQueryVariables) => variables === undefined ? ['paymentLogs.infinite'] : ['paymentLogs.infinite', variables];
;

export const SearchUsersDocument = `
    query searchUsers($where: SearchUsersWhereInput!, $take: Int!, $skip: Int) {
  searchUsers(where: $where, take: $take, skip: $skip) {
    users {
      ...SearchUser
    }
    count
  }
}
    ${SearchUserFragmentDoc}`;
export const useSearchUsersQuery = <
      TData = SearchUsersQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: SearchUsersQueryVariables,
      options?: UseQueryOptions<SearchUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SearchUsersQuery, TError, TData>(
      ['searchUsers', variables],
      fetcher<SearchUsersQuery, SearchUsersQueryVariables>(client, SearchUsersDocument, variables, headers),
      options
    );

useSearchUsersQuery.getKey = (variables: SearchUsersQueryVariables) => ['searchUsers', variables];
;

export const useInfiniteSearchUsersQuery = <
      TData = SearchUsersQuery,
      TError = ClientError
    >(
      pageParamKey: keyof SearchUsersQueryVariables,
      client: GraphQLClient,
      variables: SearchUsersQueryVariables,
      options?: UseInfiniteQueryOptions<SearchUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<SearchUsersQuery, TError, TData>(
      ['searchUsers.infinite', variables],
      (metaData) => fetcher<SearchUsersQuery, SearchUsersQueryVariables>(client, SearchUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteSearchUsersQuery.getKey = (variables: SearchUsersQueryVariables) => ['searchUsers.infinite', variables];
;

export const ViewCountDocument = `
    query viewCount($where: ViewsWhereInput!) {
  viewCount(where: $where)
}
    `;
export const useViewCountQuery = <
      TData = ViewCountQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: ViewCountQueryVariables,
      options?: UseQueryOptions<ViewCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ViewCountQuery, TError, TData>(
      ['viewCount', variables],
      fetcher<ViewCountQuery, ViewCountQueryVariables>(client, ViewCountDocument, variables, headers),
      options
    );

useViewCountQuery.getKey = (variables: ViewCountQueryVariables) => ['viewCount', variables];
;

export const useInfiniteViewCountQuery = <
      TData = ViewCountQuery,
      TError = ClientError
    >(
      pageParamKey: keyof ViewCountQueryVariables,
      client: GraphQLClient,
      variables: ViewCountQueryVariables,
      options?: UseInfiniteQueryOptions<ViewCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<ViewCountQuery, TError, TData>(
      ['viewCount.infinite', variables],
      (metaData) => fetcher<ViewCountQuery, ViewCountQueryVariables>(client, ViewCountDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteViewCountQuery.getKey = (variables: ViewCountQueryVariables) => ['viewCount.infinite', variables];
;

export const ViewsDocument = `
    query views($where: ViewsWhereInput!, $take: Int, $skip: Int) {
  views(where: $where, take: $take, skip: $skip) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const useViewsQuery = <
      TData = ViewsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: ViewsQueryVariables,
      options?: UseQueryOptions<ViewsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ViewsQuery, TError, TData>(
      ['views', variables],
      fetcher<ViewsQuery, ViewsQueryVariables>(client, ViewsDocument, variables, headers),
      options
    );

useViewsQuery.getKey = (variables: ViewsQueryVariables) => ['views', variables];
;

export const useInfiniteViewsQuery = <
      TData = ViewsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof ViewsQueryVariables,
      client: GraphQLClient,
      variables: ViewsQueryVariables,
      options?: UseInfiniteQueryOptions<ViewsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<ViewsQuery, TError, TData>(
      ['views.infinite', variables],
      (metaData) => fetcher<ViewsQuery, ViewsQueryVariables>(client, ViewsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteViewsQuery.getKey = (variables: ViewsQueryVariables) => ['views.infinite', variables];
;
