import { Footer } from "@/featuresPrincess/layout/Footer";
import { Header } from "@/featuresPrincess/layout/Header";
import { useIsFromApp } from "@/helpers/hooks/useIsFromApp";
import { BottomTab } from "@/featuresPrincess/layout/BottomTab";
import { Route } from "@/consts";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { TncAcceptUpdateOverlay } from "@/features/common/TncAcceptUpdateOverlay";

const HIDE_FOOTER_PATHS = [Route.Message] as string[];

export const TailwindLayout = ({ children }: { children: React.ReactNode }) => {
  const isFromApp = useIsFromApp();
  const router = useRouter();

  const hideFooter = useMemo(
    () => HIDE_FOOTER_PATHS.includes(router.pathname),
    [router.pathname],
  );

  return (
    <div className="flex h-[100dvh] flex-col">
      {!isFromApp && <Header />}
      <main className="flex flex-grow flex-col overflow-y-scroll" id="main">
        {children}
        {!isFromApp && !hideFooter && (
          <div className="mt-auto">
            <Footer />
          </div>
        )}

        <TncAcceptUpdateOverlay />
      </main>
      {!isFromApp && (
        <div className="md:hidden">
          <BottomTab />
        </div>
      )}
    </div>
  );
};
