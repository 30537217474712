import { cld } from "@/services/cloudinary";
import { getPublicId } from "@/services/cloudinary/getPublicId";
import { Resize } from "@cloudinary/url-gen/actions";

export const getTransformedUrl = (
  url: string,
  width: number,
  height = width,
) => {
  const publicId = getPublicId(url);
  const image = cld.image(publicId);
  const resizedImage = image.resize(
    Resize.fit().width(Math.round(width)).height(Math.round(height)),
  );
  return resizedImage.toURL();
};
